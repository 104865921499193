import { Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useCurrentPractice } from '../contexts/PracticeContext'
import { selectContext } from '../selectors/auth'
import { isUnifiedEnabled } from '../util/unified'

const FinancingDevGuard = ({ children }) => {
	const context = useSelector(selectContext)
	const { user } = context
	const { currentPracticeObject } = useCurrentPractice()

	// prevent page from redirecting to 404 while loading after refresh
	if (!Object.keys(currentPracticeObject).length) {
		return null
	}
	if (currentPracticeObject?.settings?.showFinancingDev) {
		return children
	}
	// Allow lending only if Unified login is enabled
	if (
		(isUnifiedEnabled(user?.email) || !currentPracticeObject?.stripeAccountId) &&
		currentPracticeObject?.isDashboardEnabled
	) {
		return children
	}
	return <Navigate to="/404" />
}

FinancingDevGuard.propTypes = {
	children: PropTypes.node
}

export default FinancingDevGuard
