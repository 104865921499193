/**
 * Scratch's color palette
 *
 * Theme: Dark mode
 */
const palette = {
	midnight: '#1D1D30',
	purple: '#5B70FF',
	mint: '#60EDCE'
}

// Order: Lighter to darker
export const textColor = {
	primary: '#000',
	secondary: palette.midnight,
	tertiary: '#9099DB',
	quaternary: palette.midnight,
	mint: palette.mint,
	purple: palette.purple
}

export const iconColor = {
	mint: palette.mint,
	purple: palette.purple,
	red: '#FF6768'
}

// Order: Darker to lighter
export const bgColor = {
	mint: palette.mint,
	purple: '#5B44FD',
	primary: palette.midnight,
	secondary: '#F7F8FF',
	tertiary: '#2F3344'
}

// Order: Darker to lighter
export const borderColor = {
	primary: palette.midnight,
	secondary: '?',
	tertiary: '#4D536E'
}
