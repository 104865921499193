import { gql } from 'urql'
import { Query } from './generated-types/custom-graphql'

export const LIST_ACTION_NEEDED = gql<Query>(`
  query ListActionNeeded ($skip: Int, $take: Int, $orderBy: [ActionNeededOrderByInput!], $where: ActionNeededWhereInput) {
    listActionNeeded (skip: $skip, take: $take, orderBy: $orderBy, where: $where) {
      items {
        id,
        isComplete,
        amount,
        clientId,
        clientName,
        actionItemType,
        paid,
        chargeId,
        paymentIntentId,
        invoiceId,
        metadata,
        failureCode,
        failureMessage,
        scratchPaymentMethod,
        createdAt, 
        updatedAt,
        dateAdjustedInPims,
        resolution,
        resolutionNote,
        completedBy,
        completedAt,
        notes,
        stripeCreatedDate,
        practice { 
          name,
          id,
          consolidatorReferenceCode,
          consolidatorReferenceCode2
        }
      },
      skip,
      take,
      total
    }
  }
`)
