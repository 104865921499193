/* eslint-disable camelcase */
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import common_en from './en/common.json'
import pages_en from './en/pages.json'

export const defaultNS = 'common'

export const resources = {
	en: {
		common: common_en,
		pages: pages_en
	}
}
i18n.use(initReactI18next).init({
	debug: false,
	saveMissing: true,
	fallbackLng: 'en',
	defaultNS,
	resources
})

export default i18n
