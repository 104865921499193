import { User as UserGQL } from 'gql/generated-types/custom-graphql'
import {
	PRACTICE_ROLE_ADMIN,
	PRACTICE_ROLE_HOSPITAL_MANAGER,
	PRACTICE_ROLE_REGIONAL_MANAGER,
	PRACTICE_ROLE_SCRATCH_ADMIN,
	PRACTICE_ROLE_STAFF,
	RequestLevel
} from '../constants'

// This will extract the username from the email if the email contains
// scratchpay.care as this is currently the way to create usernames
export const getUsernameOrEmail = (email) => {
	if (email && email.includes('scratchpay.care')) {
		return email.slice(0, email.indexOf('@'))
	}
	return email
}

export const canOrderTerminals = (user) => ['3', '5', '8', '11'].includes(user?.consolidatorId)

export const isScratchAdminUser = (user) => user?.isScratchAdmin

export const isAdminUser = (user: User) => !!user?.isAdmin

export const getUserPrimaryClinic = (user: User) =>
	user.primaryClinic || (user?.practices?.length && user.practices[0]) || ''

export const getRequestLevel = (user: User) => {
	if (user.isScratchAdmin) {
		return RequestLevel.SCRATCHADMIN
	}

	if (user.isAdmin) {
		return RequestLevel.ADMIN
	}

	return RequestLevel.PRACTICE
}

export const getUserRoleByPractice = (user: UserGQL, practiceId: string) => {
	if (user.isScratchAdmin) {
		return PRACTICE_ROLE_SCRATCH_ADMIN
	}

	if (user.isAdmin) {
		return PRACTICE_ROLE_ADMIN
	}

	if (user.isRegionalManager) {
		return PRACTICE_ROLE_REGIONAL_MANAGER
	}

	const userPractice = user.practices?.find(
		(x) => x.practice.id === practiceId?.replace('acct_', 'prac_')
	)

	if (userPractice) {
		if (userPractice.isRegionalManager) {
			return PRACTICE_ROLE_REGIONAL_MANAGER
		}

		if (userPractice.isHospitalManager) {
			return PRACTICE_ROLE_HOSPITAL_MANAGER
		}

		if (userPractice.isStaff) {
			return PRACTICE_ROLE_STAFF
		}
	}

	return ''
}

export const isStaff = (user: UserGQL, practiceId: string) => {
	const practiceUser = user.practices.find(
		(p) => p.practice.id === practiceId.replace('acct_', 'prac_')
	)
	return !practiceUser?.isRegionalManager && !practiceUser?.isHospitalManager
}
