import { initializeApp } from 'firebase/app'
import { getStorage, ref } from 'firebase/storage'
import { getFirestore } from 'firebase/firestore'
import {
	signOut,
	getAuth,
	signInWithEmailAndPassword as emailAndPassword,
	signInWithCustomToken as signInWithCustomTokenInFirebase
} from 'firebase/auth'
import { firebaseConfig } from '../config'

export const firebaseApp = initializeApp(firebaseConfig)

export const authClient = () => getAuth(firebaseApp)

export const storageClient = getStorage(firebaseApp, `gs://${firebaseConfig.storageBucket}`)

export const signInWithEmailAndPassword = (email, password) =>
	emailAndPassword(authClient(), email, password)

export const signInWithCustomToken = (token: string) =>
	signInWithCustomTokenInFirebase(authClient(), token)

export const logout = () => signOut(authClient())

export const uploadBytes = (location, blob) => uploadBytes(ref(storageClient, location), blob)

export const firestoreClient = () => getFirestore(firebaseApp)
