import { Badge, BadgeProps } from '@mui/material'
import useSettings from 'hooks/useSettings'

export const CustomBadge: React.FC<BadgeProps> = ({ ...props }) => {
	const { settings } = useSettings()
	const badgeColor = settings.theme === 'dark' ? 'text.purple' : 'text.mint'

	return (
		<Badge
			{...props}
			sx={{
				'& .MuiBadge-badge': {
					backgroundColor: badgeColor,
					color: 'text.primary'
				}
			}}
		/>
	)
}
