import { createSvgIcon } from '@mui/material/utils'

export const Info = createSvgIcon(
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="currentColor"
		width="385.333"
		height="385.333"
		viewBox="0 0 289 289"
	>
		<path d="M133.1 66c-14.3 3.8-25.5 10.4-35.7 21.1-10 10.6-15.8 21.8-18.8 36.2-5.2 24.8 2.3 49.6 20.4 67.7 7.8 7.8 14.9 12.6 24.5 16.4 5.3 2.2 6 2.9 14.2 15.1 4.8 7 9.7 13.4 11 14.2 4.2 2.8 7.1.4 16.8-13.9 8.7-12.7 9.2-13.1 14.8-15.4 15.4-6.1 30.5-20.3 38-35.7 5.9-12.1 7.7-19.7 7.7-33.4 0-18.3-5.4-33.7-16.9-47.9-9.3-11.5-26.2-21.8-40.6-24.9-9.9-2.1-26.3-1.8-35.4.5zM168 79.6c14.2 4.2 29.9 16.2 36.4 27.9 12.8 23.2 10.5 50.1-6.2 70.1-5.9 7.1-17.6 15.4-25.4 17.9-2.9 1-5.9 2.2-6.6 2.8-.7.5-4.2 5.2-7.8 10.3-3.6 5.2-6.6 9.4-6.8 9.4-.1 0-3.3-4.5-7-10-5.6-8.2-7.5-10.1-10.5-11.1-15.6-5.1-30.1-17.5-37-31.8-5.2-10.9-6.5-17.1-5.8-30.1.6-12.3 2.9-19.9 8.9-29.2 9.7-15 25.8-25.5 43.3-28.2 5.2-.8 18.9.3 24.5 2z" />
		<path d="M146.6 105.1c-1.7 1.3-2.6 3-2.6 4.9 0 1.9.9 3.6 2.6 4.9 3 2.4 5.2 2.6 8.9.9 4.6-2.1 4.6-9.5 0-11.6-3.7-1.7-5.9-1.5-8.9.9zM145.6 127.6c-1.3 1.3-1.6 4.6-1.6 20.8 0 21.8.3 22.6 7.4 22.6 7.7 0 7.6.4 7.6-22.5 0-19.2-.1-20.5-1.9-21.5-3-1.5-9.7-1.2-11.5.6z" />
	</svg>,
	'Adjustments'
)
