// TODO: Move this to Google Tag Manager
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { GlobalStyles } from '@mui/material'
import { selectUser } from '../selectors/auth'

// This file loads the `delighted` script in the DOM

export function loadDelightedScript() {
	/* eslint-disable @typescript-eslint/no-shadow */
	// @ts-ignore: TS1345 because it's supposed to be this way for delightedscript
	!(function (e, t, r, n) {
		if (!e[n]) {
			for (
				var i = (e[n] = []),
					a = [
						'survey',
						'reset',
						'config',
						'init',
						'set',
						'get',
						'event',
						'identify',
						'track',
						'page',
						'screen',
						'group',
						'alias'
					],
					s = 0;
				s < a.length;
				s++
			) {
				const c = a[s]
				i[c] =
					i[c] ||
					(function (e) {
						return function () {
							const t = Array.prototype.slice.call(arguments)
							// @ts-ignore
							i.push([e, t])
						}
					})(c)
			}
			// @ts-ignore
			i.SNIPPET_VERSION = '1.0.1'
			const o = t.createElement('script')
			;(o.type = 'text/javascript'),
				(o.async = !0),
				(o.src = `https://d2yyd1h5u9mauk.cloudfront.net/integrations/web/v1/library/${r}/${n}.js`)
			const u = t.getElementsByTagName('script')[0]
			// @ts-ignore
			u.parentNode.insertBefore(o, u)
		}
	})(window, document, 'nXxbzT2wTfQwfiiF', 'delightedThumbs3')
	// @ts-ignore: TS2304 because it's supposed to be this way for delightedscript
	return delightedThumbs3
}

const globalStyles = (
	<GlobalStyles
		styles={{
			body: {
				'& .delighted-web-desktop .delighted-web-survey .delighted-web-survey-close': {
					left: '0 !important',
					right: 'unset !important'
				}
			}
		}}
	/>
)

export default function DelightedSurvey() {
	const user = useSelector(selectUser)

	useEffect(() => {
		const delighted = loadDelightedScript()
		setTimeout(() => {
			// TODO their docs doesn't have an api to close the survey when user logs out in an SPA
			if (user) delighted.survey()
		}, 5000) // don't show this survey immediately when user loads the page.
	}, [user])

	return <>{globalStyles}</>
}
