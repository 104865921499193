/* eslint-disable no-await-in-loop */
import { DateTime } from 'luxon'
import { PaymentIntent, RefundCharge, Charge } from 'types/stripe'

import Stripe from 'stripe'
import { PaymentHistory } from '../gql/generated-types/custom-graphql'
import {
	getChargeById,
	getInvoiceById,
	getPaymentByClientIdAndAmount,
	getPaymentIntentById
} from '../util/db'
import { RETURNED_E_CHECK, checkScratchPaymentMethods } from '../constants'

const failureCodesToExclude = ['bank_account_unusable', 'payment_method_skip_verification_failed']

export const isFailedCheckCharge = (charge) =>
	checkScratchPaymentMethods.indexOf(charge?.metadata?.scratchPaymentMethod) > -1 &&
	(charge?.transaction_status === 'failed' ||
		charge?.transactionStatus === 'failed' ||
		charge?.status === 'failed') &&
	!failureCodesToExclude.includes(charge?.failure_code || charge?.failureCode)

const getReturnedECheckOriginalObject = async (
	originalTransactionId: string
): Promise<PaymentIntent | Charge | Stripe.Invoice | undefined> => {
	let object: PaymentIntent | Charge | Stripe.Invoice

	object = await getChargeById(originalTransactionId)
	if (object) {
		return object
	}

	// If the charge is not found in the DB, then the originalTransactionId is a paymentIntent
	// And it happens if the returned check is a lockbox check
	object = await getPaymentIntentById(originalTransactionId)

	let lockboxInvoice: Stripe.Invoice

	if (!object) {
		// If the payment intent is not found in the DB, then the originalTransactionId is an invoice
		object = await getInvoiceById(originalTransactionId)
		if (!object) {
			// This shouldn't happen
			console.error(`Charge not found for returned check ${originalTransactionId}`)
			return
		}
		lockboxInvoice = object
	} else {
		// The payment intent for lockbox doesn't contain the correct metadata
		// so we need to get the invoice and get the metadata from there

		// object.invoice may be an id or an object, if it's an object we don't need to fetch it
		// we would just return the object
		// eslint-disable-next-line
    if (typeof object.invoice === 'object') {
			lockboxInvoice = object.invoice as Stripe.Invoice
		} else {
			lockboxInvoice = await getInvoiceById(object.invoice as string)
		}
	}

	object = {
		...lockboxInvoice,
		metadata: {
			...lockboxInvoice.metadata,
			scratchPaymentMethod: 'lockbox_check'
		},
		amount: lockboxInvoice?.amount_due,
		amount_captured: lockboxInvoice?.amount_paid
	} as Stripe.Invoice
	return object
}

export const appendReturnedChecksToChargesList = async (paymentIntents, returnedChecks) => {
	const chargesMap = new Map()
	paymentIntents.forEach((paymentIntent) => {
		const charge = paymentIntent.charges?.data[0]
		if (charge) {
			chargesMap.set(charge.id, charge)
		}
	})

	for (const rowReturnedCheck of returnedChecks) {
		if (!chargesMap.has(rowReturnedCheck.id)) {
			let charge = chargesMap.get(rowReturnedCheck.originalTransactionId)

			if (!charge) {
				charge = await getReturnedECheckOriginalObject(rowReturnedCheck.originalTransactionId)
			}

			if (!charge) {
				// This shouldn't happen
				console.error(`Charge not found for returned check ${rowReturnedCheck.id}`)
				// eslint-disable-next-line no-continue
				continue
			}

			const newCharge = { ...charge }
			newCharge.status = RETURNED_E_CHECK
			newCharge.returnedECheck = true
			newCharge.id = rowReturnedCheck.id
			newCharge.created = DateTime.fromISO(rowReturnedCheck.dateAdjustedInPIMS).toUnixInteger()
			newCharge.charges = {
				data: [newCharge]
			}
			newCharge.originalStatus = charge.status
			paymentIntents.push(newCharge)
		}
	}
}

export const appendReturnedChecksToDailyTotalsList = async (charges, returnedChecks) => {
	const chargesMap = new Map()
	charges.forEach((charge) => {
		chargesMap.set(charge.id, charge)
	})

	for (const rowReturnedCheck of returnedChecks) {
		if (!chargesMap.has(rowReturnedCheck.id)) {
			let charge = chargesMap.get(rowReturnedCheck.originalTransactionId)

			if (!charge) {
				charge = await getReturnedECheckOriginalObject(rowReturnedCheck.originalTransactionId)
			}

			if (!charge) {
				// This shouldn't happen
				console.error(`Charge not found for returned check ${rowReturnedCheck.id}`)
				// eslint-disable-next-line no-continue
				continue
			}

			const newCharge = { ...charge }
			newCharge.status = RETURNED_E_CHECK
			newCharge.returnedECheck = true
			newCharge.id = rowReturnedCheck.id
			newCharge.created = DateTime.fromISO(rowReturnedCheck.dateAdjustedInPIMS).toUnixInteger()
			charges.push(newCharge)
		}
	}
}

/**
 * Check if a payment already exists on the same day, for the same client, practice and amount
 * @returns {boolean} Payment exists
 */
export const checkPaymentAlreadyExists = async (
	clientID: string,
	practiceId: string,
	amountInCents: number
) => {
	const today = new Date()
	today.setHours(0, 0, 0)
	const todayInMilliseconds = Math.floor(today.getTime() / 1000)
	const response = await getPaymentByClientIdAndAmount(
		clientID,
		practiceId,
		todayInMilliseconds,
		amountInCents
	)
	return !!response?.id
}

// This method should be used after appending returned charge to the list of charges
// and while doing the appending process, a boolean flag `returnedECheck` is set to true
export const isReturnedECheckCharge = (charge) =>
	checkScratchPaymentMethods.indexOf(charge?.metadata?.scratchPaymentMethod) > -1 &&
	charge?.status === RETURNED_E_CHECK

export const isDisputedCharge = (charge, paymentIntent) =>
	charge?.disputed || paymentIntent?.disputed

export const transactionStatus = (paymentIntent, charge) => {
	let status = charge?.status || paymentIntent.status
	// here we map the status of scratch loan payment requests
	if (paymentIntent?.metadata?.scratchpayLoanUniqueId != null) {
		const { scratchpayLoanStatus } = paymentIntent.metadata
		if (scratchpayLoanStatus === 'finalized') {
			status = 'succeeded'
		} else if (scratchpayLoanStatus === 'declined') {
			status = 'failed'
		} else {
			status = 'pending'
		}
	}

	if (paymentIntent.transactionStatus) {
		// If it's gql
		return paymentIntent.transactionStatus
	}

	if (paymentIntent.object === 'refund') {
		status = 'refunded-transactions'
	}

	if (charge?.amount_captured === 0 && charge?.status === 'succeeded') {
		status = 'uncaptured'
	}

	if (paymentIntent.originalStatus === 'failed') {
		status = 'failed'
	}

	if (paymentIntent.status === 'canceled') {
		status = 'failed'
	}

	return status
}

export const getSumPaidAmount = (charges: PaymentIntent[]) =>
	charges.reduce((a: number, v: PaymentIntent) => {
		const charge = v.charges?.data[0]
		const status = transactionStatus(v, charge)
		let amount = 0
		if (['paid', 'succeeded'].includes(status)) {
			if (isSurchargePayment(v)) {
				amount = Number(v.metadata?.amountWithoutSurcharge ?? 0)
			} else {
				amount = v.amount!
			}
		}
		return (a += amount)
	}, 0)

export const getSumRefundedAmount = (charges: PaymentIntent[]) =>
	charges.reduce((a: number, v: PaymentIntent | RefundCharge) => {
		let refundedAmount = 0
		if (v.object === 'refund') {
			if (isSurchargePayment(v)) {
				refundedAmount = Number(v.metadata?.refundAmountWithoutSurcharge || 0)
			} else {
				refundedAmount = v.amount
			}
		}
		return (a += refundedAmount)
	}, 0)

export const getSumPendingAmount = (charges) =>
	charges.reduce((a, v) => {
		const charge = v.charges?.data[0]
		const status = transactionStatus(v, charge)
		const amount = ['open', 'pending', 'requires_payment_method', 'uncollectible'].includes(status)
			? v.amount
			: 0
		return (a += amount)
	}, 0)

export const getSumFailedECheckAmount = (charges) =>
	charges.reduce((a, v) => {
		const charge = v.charges?.data[0]
		return (a += isFailedCheckCharge(charge || v) ? (v?.amount ?? 0) : 0)
	}, 0)

export const getSumReturnedECheckAmount = (charges) =>
	charges.reduce((a, v) => {
		const charge = v.charges?.data[0]
		return (a += isReturnedECheckCharge(charge || v) ? (v?.amount ?? 0) : 0)
	}, 0)

export const getSumFailedAmount = (charges) =>
	charges.reduce((a, v) => {
		const charge = v.charges?.data[0]
		const status = transactionStatus(v, charge)
		return (a +=
			status === 'failed' && !isFailedCheckCharge(charge) && !isReturnedECheckCharge(charge)
				? v.amount
				: 0)
	}, 0)

export const getSumUncapturedAmount = (charges) =>
	charges.reduce((a, v) => {
		const charge = v.charges?.data[0]
		const status = transactionStatus(v, charge)
		return (a += status === 'uncaptured' ? v.amount : 0)
	}, 0)

export const isAchPaymentRequest = (charge) =>
	charge.metadata?.scratchPaymentMethod === 'payment_request' &&
	charge.payment_method_details?.type === 'us_bank_account'

export const isInteracPaymentType = (charge) =>
	charge?.payment_method_details?.type === 'interac_present' || // Payment History v1
	charge?.paymentMethodType === 'interac_present' // Payment History v2

export const isSurchargePayment = (
	paymentIntent: PaymentIntent | RefundCharge | PaymentHistory | Stripe.Charge
) => {
	if ('surcharge' in paymentIntent) {
		return paymentIntent.surcharge ?? false
	}
	return paymentIntent?.metadata?.surcharge === 'true'
}

export const isRefundPayment = (paymentIntent: PaymentIntent | RefundCharge | PaymentHistory) =>
	paymentIntent.object === 'refund'
