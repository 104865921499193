import { useRef, useState, useEffect } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Badge, Box, Divider, IconButton, Popover, Tooltip, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { makeStyles } from 'tss-react/mui'
import CogIcon from '../../icons/Cog'
import Scrollbar from '../Scrollbar'
import { useCurrentPractice } from '../../contexts/PracticeContext'
import BubbleConversationIcon from '../../icons/BubbleConversation'
import { selectUser } from '../../selectors/auth'
import Loadable from '../Loadable'
import { lazyWithRetry } from 'util/lazy-with-retry'

const useStyles = makeStyles()(() => ({
	badge: {
		backgroundColor: '#60EDCE',
		color: '#1D1C2F'
	}
}))

const ChatThreadList = Loadable(lazyWithRetry(() => import('./chat/ChatThreadList')))

const ChatPopover = () => {
	const anchorRef = useRef(null)
	const user = useSelector(selectUser)
	const { currentPracticeObject } = useCurrentPractice()
	const { unreadMessages } = useSelector((state: any) => state.chat)
	const [sumUnreadMessages, setSumUnreadMessages] = useState(0)
	const { classes } = useStyles()

	useEffect(() => {
		const unreadCounts = Object.values(unreadMessages) as number[]
		const sumUnreadCount = unreadCounts.reduce((a: number, v: number) => (a += v), 0)
		setSumUnreadMessages(sumUnreadCount)
	}, [unreadMessages])

	const [open, setOpen] = useState(false)

	const handleOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	return (
		<>
			<Box
				// component={ButtonBase} // TODO TSC this causes a button nested in button warning
				onClick={handleOpen}
				ref={anchorRef}
				sx={{
					alignItems: 'center',
					display: 'flex'
				}}
			>
				<Tooltip title="Chat Threads">
					<IconButton color="inherit" onClick={handleOpen} ref={anchorRef} size="large">
						{sumUnreadMessages > 0 && (
							<Badge
								classes={{ badge: classes.badge }}
								badgeContent={sumUnreadMessages}
								// size="small"
							>
								<BubbleConversationIcon
									fontSize="small"
									// color="midnight" // TODO: color on icons is in the theme
								/>
							</Badge>
						)}
						{sumUnreadMessages === 0 && (
							<BubbleConversationIcon
								fontSize="small"
								// color="midnight" // TODO: color on icons is in the theme
							/>
						)}
					</IconButton>
				</Tooltip>
			</Box>
			<Popover
				anchorEl={anchorRef.current}
				anchorOrigin={{
					horizontal: 'center',
					vertical: 'bottom'
				}}
				keepMounted
				onClose={handleClose}
				open={open}
				PaperProps={{
					sx: { p: 0.0, width: 300 }
				}}
			>
				<Box sx={{ p: 2 }}>
					<Typography color="textPrimary" variant="subtitle2">
						{`User: ${user?.name}`}
					</Typography>
					<Typography color="textPrimary" variant="subtitle2">
						{`Location: ${currentPracticeObject?.clinicName}`}
					</Typography>
				</Box>
				<Divider />
				<Box sx={{ m: 2 }}>
					<IconButton component={RouterLink} color="secondary" size="small" to="/dashboard/chat">
						<CogIcon />
						<Typography color="textPrimary" variant="subtitle2">
							See all Chats
						</Typography>
					</IconButton>
				</Box>
				<Divider />
				<Box sx={{ p: 2 }}>
					<Scrollbar options={{ suppressScrollX: true }}>
						<Box sx={{ display: undefined }}>
							<ChatThreadList />
						</Box>
					</Scrollbar>
				</Box>
			</Popover>
		</>
	)
}

export default ChatPopover
