import { createSlice } from '@reduxjs/toolkit'

// TODO TSC any
const initialState: any = {
	list: []
}

const slice = createSlice({
	name: 'practiceInstance',
	initialState,
	reducers: {
		addPracticeInstance(state, action) {
			state.list.push(action.payload)
		},
		emptyPracticeInstanceList(state) {
			state.list = []
		}
	}
})

export const { reducer } = slice
export const { addPracticeInstance, emptyPracticeInstanceList } = slice.actions

export default slice
