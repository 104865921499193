import { checkoutPaymentServiceAxiosClient } from './base'

const GET_CONNECTION_TOKEN_URL = '/key/terminal'
const POST_CANCEL_PAYMENT_INTENT = (id) => `/payment-intent/${id}/cancel`
const POST_PAYMENT_REQUEST = '/payment-request'
const SEND_PAYMENT_REQUEST_INVOICE = '/payment-request/invoice'
const CREATE_PAYMENT_INTENT_PATH = (paymentMethodType) => `/payment-intent/${paymentMethodType}`

const UPDATE_PAYMENT_METHOD_URL = (paymentMethodId: string) => `/payment-method/${paymentMethodId}`

const READER_PROCESS_REQUEST = '/reader/process'
const READER_CANCEL_REQUEST = '/reader/cancel'
const READER_SIMULATE_SWIPE = '/reader/simulate-swipe'
const DELETE_SIMULATED_READER = (readerId) => `/reader/${readerId}`
const PAYMENT_INSTALLMENT = '/payment-installment'
const BANK_PAYMENT_PATH = '/bank-payment'

export const getTerminalConnectionToken = () =>
	checkoutPaymentServiceAxiosClient.get(GET_CONNECTION_TOKEN_URL)
export const cancelPaymentIntent = (paymentIntentId) => {
	const url = POST_CANCEL_PAYMENT_INTENT(paymentIntentId)
	return checkoutPaymentServiceAxiosClient.post(url)
}

export const createPaymentRequest = (data: CreatePaymentRequestBody) =>
	checkoutPaymentServiceAxiosClient.post(POST_PAYMENT_REQUEST, data)

export const sendPaymentRequestInvoice = (data) =>
	checkoutPaymentServiceAxiosClient.post(SEND_PAYMENT_REQUEST_INVOICE, data)

export const updatePaymentMethod = (paymentMethodId, data) =>
	checkoutPaymentServiceAxiosClient.patch(UPDATE_PAYMENT_METHOD_URL(paymentMethodId), data)

export const processReaderPayment = (readerId, paymentIntentId) =>
	checkoutPaymentServiceAxiosClient.post(READER_PROCESS_REQUEST, {
		reader_id: readerId,
		payment_intent_id: paymentIntentId
	})
export const cancelReaderPayment = (readerId) =>
	checkoutPaymentServiceAxiosClient.post(READER_CANCEL_REQUEST, {
		reader_id: readerId
	})

export const simulateReaderSwipe = (readerId, brand, funding, error) =>
	checkoutPaymentServiceAxiosClient.post(READER_SIMULATE_SWIPE, {
		reader_id: readerId,
		brand,
		funding,
		error
	})

export const deleteSimulatedReader = (readerId) =>
	checkoutPaymentServiceAxiosClient.delete(DELETE_SIMULATED_READER(readerId))

export const createInstallmentPlan = (data) =>
	checkoutPaymentServiceAxiosClient.post(PAYMENT_INSTALLMENT, data)

export const deleteInstallmentPlan = (subscriptionId) =>
	checkoutPaymentServiceAxiosClient.delete(`${PAYMENT_INSTALLMENT}/${subscriptionId}`)

export const updateInstallmentPlan = (subscriptionId, data) =>
	checkoutPaymentServiceAxiosClient.patch(`${PAYMENT_INSTALLMENT}/${subscriptionId}`, data)

export const createCheckoutPaymentServicePaymentIntent = async (
	req: CreateCheckoutPaymentServicePaymentIntentRequest
) =>
	checkoutPaymentServiceAxiosClient.post<
		CreateCheckoutPaymentServicePaymentIntentResponse,
		CreateCheckoutPaymentServicePaymentIntentRequestBody
	>(CREATE_PAYMENT_INTENT_PATH(req.params.paymentMethodType), req.body)

export const createBankPayment = (data) =>
	checkoutPaymentServiceAxiosClient.post(BANK_PAYMENT_PATH, data)

export const confirmPaymentIntentByCheckoutPaymentService = (
	req: ConfirmCheckoutPaymentServicePaymentIntentRequest
) =>
	checkoutPaymentServiceAxiosClient.post(
		`/payment-intent/${req.params.paymentIntentId}/confirm`,
		req.body
	)
