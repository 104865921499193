import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
	canViewStripeRequirementsBanner,
	hasPermissionForUpdatingStripeRequirements
} from 'components/dashboard/practices/helpers'
import { useCurrentPractice } from '../contexts/PracticeContext'
import { updateAccountInfo } from '../apis/index'
import { selectContext, selectUser } from '../selectors/auth'

const shouldTriggerPracticeRequiredInfoLogic = (
	currentPracticeObject: IPractice,
	currentUser: User,
	context: Context
) => {
	// Don't trigger practice required info if user hasn't permission to view
	if (!canViewStripeRequirementsBanner(currentPracticeObject, currentUser)) {
		return false
	}

	// Don't trigger practice required info if user hasn't permission to update
	return hasPermissionForUpdatingStripeRequirements(context, currentPracticeObject)
}

const PracticeRequiredInfoUpdater = () => {
	const { currentPracticeObject } = useCurrentPractice()
	const user = useSelector(selectUser)
	const context = useSelector(selectContext)

	useEffect(() => {
		if (shouldTriggerPracticeRequiredInfoLogic(currentPracticeObject, user, context)) {
			setTimeout(() => updateAccountInfo(currentPracticeObject.stripeAccountId), 3000)
		}
	}, [currentPracticeObject, user, context])

	return null
}

export default PracticeRequiredInfoUpdater
