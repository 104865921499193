import { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import {
	Alert,
	Autocomplete,
	AppBar,
	Box,
	Hidden,
	IconButton,
	TextField,
	Toolbar
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { makeStyles } from 'tss-react/mui'
import { useSelector } from 'react-redux'
import { debounce } from 'lodash'

import { HEADER_HEIGHT, THEMES } from '../../constants'
import useSettings from '../../hooks/useSettings'
import MenuIcon from '../../icons/Menu'
import AccountPopover from './AccountPopover'
import TerminalPopover from './TerminalPopover'
import NotificationsPopoverV2 from './NotificationsPopoverV2'
import ChatPopover from './ChatPopover'
import Moon from '../../icons/Moon'
import Sun from '../../icons/Sun'
import { isUnifiedEnabled, queryPractices, useClinicSelect } from '../../util/unified'
import { useCurrentPractice } from '../../contexts/PracticeContext'
import { isCheckOnlyPractice } from '../../util/practice'
import { useReader } from '../../contexts/ReaderContext'
import { selectContext } from '../../selectors/auth'

const useStyles = makeStyles()(() => ({
	locationPicker: {
		'& .MuiOutlinedInput-root': {
			fontSize: '12px',
			color: 'inherit'
		},
		'&:not(.Mui-focused)': {
			width: 'auto',
			minWidth: '200px',
			'& .MuiOutlinedInput-notchedOutline': {
				borderWidth: 0
			},
			'& input': {
				fontSize: 'inherit'
			}
		},
		'& + .MuiAutocomplete-popper': {
			width: '350px !important'
		}
	}
}))

const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
	...(theme.palette.mode === 'light' && {
		backgroundColor: (theme.palette.background as any).secondary, // TODO TSC theme
		color: theme.palette.text.secondary
	}),
	...(theme.palette.mode === 'dark' && {
		backgroundColor: (theme.palette.background as any).secondary, // TODO TSC theme
		borderBottom: `1px solid ${theme.palette.divider}`
	}),
	zIndex: theme.zIndex.drawer + 100
}))

const DashboardNavbar = (props) => {
	const { onSidebarMobileOpen, ...other } = props
	const { settings, saveSettings } = useSettings()
	const context = useSelector(selectContext)
	const { user } = context
	const { currentPracticeObject, practiceID, setPracticeID } = useCurrentPractice()
	const financingOnly = useMemo(
		() => !currentPracticeObject?.stripeAccountId && currentPracticeObject?.isDashboardEnabled,
		[currentPracticeObject]
	)

	// TO DO: MAKE SURE ALL USERS WHERE isAdmin === true HAVE consolidatorId
	const [darkMode, setDarkMode] = useState(settings.theme === 'dark')

	const { unsavedChanges } = useReader()

	// Use dynamic loading for practice selector
	const dynamicPracticesLoad = isUnifiedEnabled(user?.email) && user?.isScratchAdmin

	const {
		status: organizationsStatus,
		data: organizations,
		error: organizationsError
	} = useClinicSelect(context)

	// Only used for scratch admins
	const [options, setOptions] = useState<IPractice[]>([])

	const [value, setValue] = useState()
	const [inputValue, setInputValue] = useState(practiceID)
	const { classes } = useStyles()

	const handleSwitchTheme = () => {
		saveSettings({
			...settings,
			theme: settings.theme === 'light' ? THEMES.DARK : THEMES.LIGHT
		})

		setDarkMode(settings.theme === 'light')
	}

	// Do type-ahead style practice load for scratch admins instead of pre-loading.
	const loadAdminPractices = useMemo(
		() =>
			debounce(async (userInput: string | undefined) => {
				if (dynamicPracticesLoad) {
					if (userInput === '' || userInput === undefined) {
						setOptions(value || currentPracticeObject ? [value || currentPracticeObject] : [])
						return undefined
					}

					queryPractices(userInput, options, setOptions)
				}
			}, 400),
		[dynamicPracticesLoad]
	)

	return (
		<DashboardNavbarRoot {...other} elevation={0}>
			<Toolbar
				disableGutters
				sx={{
					alignItems: 'center',
					display: 'flex',
					height: HEADER_HEIGHT,
					px: 3,
					py: 1
				}}
			>
				<Hidden lgUp>
					<IconButton color="inherit" onClick={onSidebarMobileOpen} size="large">
						<MenuIcon fontSize="small" />
					</IconButton>
				</Hidden>
				{organizationsError && (
					<Box mb={3}>
						<Alert severity="error">{organizationsError.message}</Alert>
					</Box>
				)}
				{organizationsStatus !== 'loading' &&
					organizations &&
					organizations.length === 0 &&
					currentPracticeObject &&
					!dynamicPracticesLoad && (
						<TextField
							label={`${user?.primaryClinicName}`}
							sx={{ width: '30ch' }}
							size="small"
							disabled
						/>
					)}
				{organizationsStatus !== 'loading' &&
					organizations &&
					organizations.length > 0 &&
					currentPracticeObject &&
					!dynamicPracticesLoad && (
						<Autocomplete
							disabled={unsavedChanges}
							className={classes.locationPicker}
							disableClearable
							disablePortal
							autoComplete
							groupBy={(option) =>
								(option.clinicName
									? option.clinicName[0].toUpperCase()
									: option.clinicCountry || option.id) || ''
							}
							options={organizations}
							getOptionLabel={(option) =>
								`${option.clinicName}  (${option.clinicCity}, ${option.clinicState})`
							}
							isOptionEqualToValue={(option, newValue) =>
								option.id ? option.id === newValue.id : false
							}
							fullWidth
							sx={{ maxWidth: 350 }}
							value={value || currentPracticeObject}
							onChange={(event, newValue: any) => {
								if (typeof newValue === 'string') {
									setTimeout(() => null)
								} else if (newValue && newValue.inputValue) {
									setTimeout(() => null)
								} else {
									setPracticeID(newValue.id)
									setValue(newValue)
								}
							}}
							onInputChange={(event, newInputValue) => {
								setInputValue(newInputValue)
							}}
							renderInput={(params) => <TextField {...params} />}
						/>
					)}
				{currentPracticeObject && dynamicPracticesLoad && (
					<Autocomplete
						disabled={unsavedChanges}
						className={classes.locationPicker}
						disableClearable
						disablePortal
						autoComplete
						filterOptions={(x) => x}
						groupBy={(option) =>
							(option.clinicName
								? option.clinicName[0].toUpperCase()
								: option.clinicCountry || option.id) || ''
						}
						options={options}
						getOptionLabel={(option) =>
							option.clinicName
								? `${option.clinicName}  (${option.clinicCity}, ${option.clinicState})`
								: ''
						}
						isOptionEqualToValue={(option, newValue) => option.id === newValue.id}
						noOptionsText="Type to search"
						fullWidth
						sx={{ maxWidth: 350 }}
						value={value || currentPracticeObject}
						onChange={(event, newValue: any) => {
							if (typeof newValue === 'string') {
								setTimeout(() => null)
							} else if (newValue && newValue.inputValue) {
								setTimeout(() => null)
							} else {
								setPracticeID(newValue.id)
								setValue(newValue)
							}
						}}
						onInputChange={(event, newInputValue, reason) => {
							setInputValue(newInputValue)
							if (reason !== 'reset' && newInputValue !== inputValue) {
								loadAdminPractices(newInputValue)
							}
						}}
						renderInput={(params) => <TextField {...params} label="Pick a practice" />}
					/>
				)}
				<Box
					sx={{
						flexGrow: 1,
						ml: 2
					}}
				/>
				<Box sx={{ ml: 1 }}>
					<IconButton color="inherit" onClick={handleSwitchTheme} size="large">
						{darkMode ? <Sun /> : <Moon />}
					</IconButton>
				</Box>
				{(user?.isScratchAdmin === true || currentPracticeObject?.isChatEnabled) && (
					<Box sx={{ ml: 1 }}>
						<ChatPopover />
					</Box>
				)}
				{!isCheckOnlyPractice(currentPracticeObject) && !financingOnly && (
					<Box sx={{ ml: 1 }}>
						<TerminalPopover />
					</Box>
				)}
				{!financingOnly && (
					<Box sx={{ ml: 1 }}>
						<NotificationsPopoverV2 />
					</Box>
				)}

				<Box sx={{ ml: 2 }}>
					<AccountPopover />
				</Box>
			</Toolbar>
		</DashboardNavbarRoot>
	)
}

DashboardNavbar.propTypes = {
	onSidebarMobileOpen: PropTypes.func
}

export default DashboardNavbar
