/**
 * Scratch's color palette
 *
 * Theme: Dark mode
 * Ordering logic: Brighter to dimmer
 */

// Order: Lighter to darker
export const textColor = {
	primary: '#fff',
	secondary: '#C5DDFF',
	tertiary: '#91A2C6',
	quaternary: '#1D1D30',
	mint: '#60EDCE',
	purple: '#5B70FF'
}

export const iconColor = {
	mint: '#60EDCE',
	purple: '#5B70FF',
	red: '#FF6768'
}

// Order: Darker to lighter
export const bgColor = {
	mint: '#60EDCE',
	purple: '#5B44FD',
	primary: '#1D1D30',
	secondary: '#272A39',
	tertiary: '#2F3344'
}

// Order: Darker to lighter
export const borderColor = {
	primary: '#1D1D30',
	secondary: '?',
	tertiary: '#4D536E'
}
