import { useState } from 'react'
import toast from 'react-hot-toast'
import { checkPaymentAlreadyExists } from 'services/charge'

/**
 * Hook `useRecentClientPaymentConfirmation`
 *
 * Custom hook for displaying a confirmation dialog when making a potentially duplicate payment for the same client, practice, and amount on the same day.
 *
 */
export const useRecentClientPaymentConfirmation = () => {
	const [amount, setAmount] = useState(0)
	const [clientId, setClientId] = useState('')
	const [showRecentClientPaymentDialog, setShowRecentClientPaymentDialog] = useState<boolean>(false)
	const [confirmRecentPaymentDialog, setConfirmRecentPaymentDialog] = useState<
		{ resolve } | undefined
	>(undefined)

	const handleConfirmRecentClientPayment = (currentClientId: string, amountInCents: number) => {
		const promise = new Promise((resolve) => {
			setAmount(amountInCents)
			setClientId(currentClientId)
			setShowRecentClientPaymentDialog(true)
			setConfirmRecentPaymentDialog({ resolve })
		})

		promise.finally(() => {
			setShowRecentClientPaymentDialog(false)
			setAmount(0)
			setClientId('')
		})

		return promise
	}

	const handleAcceptRecentClientPayment = () => {
		confirmRecentPaymentDialog && confirmRecentPaymentDialog.resolve(true)
	}

	const handleCloseRecentClientPaymentDialog = () => {
		confirmRecentPaymentDialog && confirmRecentPaymentDialog.resolve(false)
	}

	/**
	 * Check if payment should be created when detecting potential duplicates,
	 * and present a dialog for the user to confirm the action when duplicate payments are identified.
	 * @returns [ShouldCreatePayment, ToastWasDismissed]
	 */
	const checkIfShouldCreatePayment = async (
		clientID: string,
		practiceID: string,
		amountInCents: number,
		toastId: string
	) => {
		const paymentAlreadyExists = await checkPaymentAlreadyExists(
			clientID,
			practiceID,
			amountInCents
		)

		if (paymentAlreadyExists) {
			toastId.length && toast.dismiss(toastId)
			const shouldCreate = await handleConfirmRecentClientPayment(clientID, amountInCents)
			return [shouldCreate, true]
		}

		return [true, false]
	}

	return {
		amount,
		checkIfShouldCreatePayment,
		clientId,
		showRecentClientPaymentDialog,
		handleConfirmRecentClientPayment,
		handleAcceptRecentClientPayment,
		handleCloseRecentClientPaymentDialog
	}
}
