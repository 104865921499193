import { useRef, useState } from 'react'
import { Badge, IconButton, Popover, Tooltip } from '@mui/material'
import DeviceTablet from '../../icons/DeviceTablet'
import { useReader } from '../../contexts/ReaderContext'
import Loadable from '../Loadable'
import { lazyWithRetry } from 'util/lazy-with-retry'

const DiscoverTerminal = Loadable(lazyWithRetry(() => import('./newTransaction/DiscoverTerminal')))

const TerminalPopover = () => {
	const { connectionStatus } = useReader()
	const anchorRef = useRef(null)
	const [open, setOpen] = useState(false)

	const handleOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	return (
		<>
			<Tooltip title="Card Reader Status">
				<IconButton color="inherit" onClick={handleOpen} ref={anchorRef} size="large">
					{connectionStatus !== 'connected' && (
						<Badge color="error" badgeContent="!">
							<DeviceTablet fontSize="small" />
						</Badge>
					)}
					{connectionStatus === 'connected' && <DeviceTablet fontSize="small" />}
				</IconButton>
			</Tooltip>

			<Popover
				anchorEl={anchorRef.current}
				anchorOrigin={{
					horizontal: 'center',
					vertical: 'bottom'
				}}
				onClose={handleClose}
				open={open}
				PaperProps={{
					sx: { p: 0.0, width: 470 }
				}}
			>
				<DiscoverTerminal popoverView />
			</Popover>
		</>
	)
}

export default TerminalPopover
