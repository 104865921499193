class ChatClient {
	private chatClient

	async init() {
		this.chatClient = null
	}

	get chatSDK() {
		return this.chatClient
	}

	set chatSDK(newChatClient) {
		this.chatClient = newChatClient
	}
}

export default new ChatClient()
