/* eslint-disable no-console */

import { Component, ErrorInfo, ReactNode } from 'react'
import { logout } from '../../lib/firebase'

interface State {
	hasError: boolean
}

interface IProps {
	children?: ReactNode
}

class ErrorBoundary extends Component<IProps, State> {
	constructor(props: IProps) {
		super(props)

		// Define a state variable to track whether is an error or not
		this.state = { hasError: false }
	}

	static getDerivedStateFromError() {
		// Update state so the next render will show the fallback UI
		return { hasError: true }
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		// You can use your own error logging service here
		if (process.env.NODE_ENV !== 'production') {
			console.error('Error Boundary')
			console.error(error)
			console.error(errorInfo)
		}
	}

	static async handleLogout() {
		await logout()
		window.location.assign('/')
	}

	render() {
		const { hasError } = this.state
		const { children } = this.props

		// Check if the error is thrown
		if (hasError) {
			// You can render any custom fallback UI
			return (
				<div
					style={{
						height: '100vh',
						textAlign: 'center',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center'
					}}
				>
					<div>
						<h2>Oops, something went wrong!</h2>
						<button
							type="button"
							onClick={() => this.setState({ hasError: false })}
							style={{
								marginRight: '16px'
							}}
						>
							Try again?
						</button>
						<button type="button" onClick={() => ErrorBoundary.handleLogout()}>
							Logout
						</button>
					</div>
				</div>
			)
		}

		// Return children components in case of no error
		return children
	}
}

export default ErrorBoundary
