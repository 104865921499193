import PropTypes from 'prop-types'

import { Box, Skeleton, Typography } from '@mui/material'

export const ResourceLoading = ({ text = 'Loading resource data' }) => (
	<>
		<Box sx={{ p: 2 }}>
			<Skeleton height={42} />
			<Skeleton animation={false} height={42} />
			<Skeleton animation="wave" height={42} />
		</Box>
		<Typography color="textSecondary" sx={{ mt: 2 }} variant="h6" align="center">
			{text}
		</Typography>
	</>
)

ResourceLoading.propTypes = {
	text: PropTypes.string
}

export default ResourceLoading
