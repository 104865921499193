// TODO: remove these methods while refactoring and use apiRequest ./util.js
import { authClient } from '../lib/firebase'

const baseUrl = process.env.REACT_APP_CHECKOUT_WEB_BASE_URL

export const constructAuthHeader = async () => {
	const accessToken = authClient().currentUser
		? await authClient().currentUser?.getIdToken()
		: undefined
	return `Bearer ${accessToken}`
}

export async function fetchGetJSON(url) {
	try {
		url = baseUrl + url
		const authHeader = await constructAuthHeader()
		const data = await fetch(url, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: authHeader
			}
		}).then((res) => res.json())
		return data
	} catch (err: any) {
		throw new Error(err.message)
	}
}

export async function fetchPostJSON(url, data) {
	try {
		const response = await fetchPost(url, data)
		return await response.json() // parses JSON response into native JavaScript objects
	} catch (err: any) {
		throw new Error(err.message)
	}
}

export async function fetchBin(url, data) {
	try {
		const response = await fetchPost(url, data)
		return await response.blob()
	} catch (err: any) {
		throw new Error(err.message)
	}
}

async function fetchPost(url, data) {
	url = baseUrl + url
	const authHeader = await constructAuthHeader()
	// Default options are marked with *
	return fetch(url, {
		method: 'POST', // *GET, POST, PUT, DELETE, etc.
		mode: 'cors', // no-cors, *cors, same-origin
		cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
		credentials: 'same-origin', // include, *same-origin, omit
		headers: {
			'Content-Type': 'application/json',
			Authorization: authHeader
		},
		redirect: 'follow', // manual, *follow, error
		referrerPolicy: 'no-referrer', // no-referrer, *client
		body: JSON.stringify(data || {}) // body data type must match "Content-Type" header
	})
}
