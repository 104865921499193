import PracticeRequiredInfoUpdator from './practiceRequiredInfoUpdater'
import FrontAppChatWidget from './frontAppChatWidget'

const Hooks = () => (
	<>
		<PracticeRequiredInfoUpdator />
		{FrontAppChatWidget()}
	</>
)

export default Hooks
