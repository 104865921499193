import { ComponentType, lazy, LazyExoticComponent } from 'react'

export const lazyWithRetry = <T extends ComponentType>(
	callback: () => Promise<any>
): LazyExoticComponent<T> => {
	return lazy(async () => {
		try {
			return await callback()
		} catch (err) {
			if ((err as Error)?.name === 'ChunkLoadError') {
				const urlParams = new URLSearchParams(window.location.search)
				// Added a query param to prevent a loop
				if (!urlParams.has('refresh')) {
					urlParams.set('refresh', 'true')
					window.location.search = urlParams.toString()
					return { default: () => null } // This line will never be executed
				}
			}
			throw err
		}
	})
}
