import { createSvgIcon } from '@mui/material/utils'

const shapeStyle = {
	fill: 'none',
	stroke: 'currentColor',
	strokeLinecap: 'round',
	strokeLinejoin: 'round',
	strokeWidth: '9px'
} as any

const Gift = createSvgIcon(
	<svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144 144">
		<rect x="30.22" y="54.14" width="85.53" height="71.23" rx="15.28" style={shapeStyle} />
		<line x1="73.31" y1="53.07" x2="73.31" y2="124.1" style={shapeStyle} />
		<line x1="31.04" y1="89.55" x2="115.26" y2="89.55" style={shapeStyle} />
		<path
			d="M74.82,53.87c0-1.85-1.87-4.52-2.56-6.28-1-2.61-2-5.27-3-7.89-3.68-9.61-7.18-25.09-20.13-25.09a14.59,14.59,0,0,0-14.6,14.6,13.23,13.23,0,0,0,4.52,10.1c4.25,3.75,10.05,5.3,15.26,7.18l20.43,7.36Z"
			style={shapeStyle}
		/>
		<path
			d="M72.37,53.87c0-1.85,1.87-4.52,2.57-6.28,1-2.61,2-5.27,3-7.89C81.59,30.09,85.09,14.61,98,14.61a14.6,14.6,0,0,1,14.6,14.6,13.27,13.27,0,0,1-4.52,10.1c-4.25,3.75-10.05,5.3-15.27,7.18L72.43,53.85Z"
			style={shapeStyle}
		/>
	</svg>,
	'Gift'
)

export default Gift
