import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	title: '',
	body: '',
	clientFirstName: '{{clientFirstName}}',
	salutation: true,
	signature: true
}

const slice = createSlice({
	name: 'oneTimeEmail',
	initialState,
	reducers: {
		setOneTimeEmailTemplateVariables: (state, action) => {
			state.title = action.payload.title
			state.body = action.payload.body
			state.salutation = action.payload.salutation
			state.signature = action.payload.signature
		}
	}
})

export const { reducer } = slice
export const { setOneTimeEmailTemplateVariables } = slice.actions
export default slice
