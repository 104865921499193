import { createSlice } from '@reduxjs/toolkit'

const actionNeededSlice = createSlice({
	name: 'actionNeeded',
	initialState: {
		actionNeededCount: 0,
		actionNeededList: [],
		consolidatorActionItemsCount: 0
	},
	reducers: {
		setActionNeededList: (state, action) => {
			state.actionNeededList = action.payload
		},
		setActionNeededCount: (state, action) => {
			state.actionNeededCount = action.payload
		},
		setConsolidatorActionItemsCount: (state, action) => {
			state.consolidatorActionItemsCount = action.payload
		}
	}
})

export const { setActionNeededList, setActionNeededCount, setConsolidatorActionItemsCount } =
	actionNeededSlice.actions

export const { reducer } = actionNeededSlice
export default actionNeededSlice
