import { Button } from '@mui/material'
import { NavLink } from 'react-router-dom'
import { useBannerStyles } from './BannerStyles'
import { BannerButtonProps } from './banner.d'

const BannerButton = ({
	href,
	buttonTitle,
	onButtonClick,
	disabled,
	variant
}: BannerButtonProps) => {
	const { classes } = useBannerStyles()
	const bannerBtnClass = classes[`${variant}-btn`] as React.CSSProperties

	return (
		<Button
			onClick={onButtonClick}
			className={`${classes.button} ${bannerBtnClass}`}
			disabled={disabled}
			{...(!!href && {
				LinkComponent: NavLink,
				to: href
			})}
		>
			{buttonTitle}
		</Button>
	)
}

export default BannerButton
