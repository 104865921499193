import { isEmpty } from 'lodash'

class CustomizedLabels {
	private customizedLabels

	async init() {
		this.customizedLabels = null
	}

	get customLabels() {
		return this.customizedLabels
	}

	set customLabels(labels) {
		this.customizedLabels = labels
	}

	getCustomLabel(key, defaultLabel) {
		const keys = key.split('.')
		let customizedLabelsSearch = this.customizedLabels

		if (isEmpty(customizedLabelsSearch)) {
			return defaultLabel
		}

		for (const path of keys) {
			if (!Object.prototype.hasOwnProperty.call(customizedLabelsSearch, path)) {
				return defaultLabel
			}
			// nosemgrep prototype-pollution-loop
			customizedLabelsSearch = customizedLabelsSearch[path]
		}

		return typeof customizedLabelsSearch === 'string' && customizedLabelsSearch.length > 0
			? customizedLabelsSearch
			: defaultLabel
	}
}

export default new CustomizedLabels()
