import { useRef, useState } from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import {
	Avatar,
	Box,
	Button,
	ButtonBase,
	Divider,
	ListItemIcon,
	ListItemText,
	MenuItem,
	Popover,
	Typography
} from '@mui/material'
import { useSelector } from 'react-redux'
import CogIcon from '../../icons/Cog'
import getInitials from '../../util/getInitials'
import { selectUser } from '../../selectors/auth'
import { logout } from '../../lib/firebase'

const AccountPopover = () => {
	const anchorRef = useRef(null)
	const user: any = useSelector(selectUser)
	const navigate = useNavigate()

	const [open, setOpen] = useState(false)

	const handleOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	const handleLogout = async () => {
		try {
			handleClose()
			await logout()
			navigate('/')
		} catch (err) {
			console.error(err)
			toast.error('Unable to logout')
		}
	}

	return (
		<>
			<Box
				component={ButtonBase}
				onClick={handleOpen}
				ref={anchorRef}
				sx={{
					alignItems: 'center',
					display: 'flex'
				}}
			>
				<Avatar
					src={user.avatar}
					sx={{
						height: 32,
						width: 32
					}}
				>
					{user.name && getInitials(user?.name)}
				</Avatar>
			</Box>
			<Popover
				anchorEl={anchorRef.current}
				anchorOrigin={{
					horizontal: 'center',
					vertical: 'bottom'
				}}
				keepMounted
				onClose={handleClose}
				open={open}
				PaperProps={{
					sx: { width: 240 }
				}}
			>
				<Box sx={{ p: 2 }}>
					<Typography color="textPrimary" variant="subtitle2">
						{user.name}
					</Typography>
				</Box>
				<Divider />
				<Box sx={{ mt: 2 }}>
					{/* <MenuItem
            component={RouterLink}
            to="/dashboard/account/profile"
          >
            <ListItemIcon>
              <UserIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={(
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                >
                  Manage Users
                </Typography>
              )}
            />
          </MenuItem> */}
					<MenuItem component={RouterLink} to="/dashboard/account">
						<ListItemIcon>
							<CogIcon fontSize="small" />
						</ListItemIcon>
						<ListItemText
							primary={
								<Typography color="textPrimary" variant="subtitle2">
									Settings
								</Typography>
							}
						/>
					</MenuItem>
				</Box>
				<Box sx={{ p: 2 }}>
					<Button
						color="primary"
						fullWidth
						onClick={handleLogout}
						variant="outlined"
						data-cy="logout-button"
					>
						Logout
					</Button>
				</Box>
			</Popover>
		</>
	)
}

export default AccountPopover
