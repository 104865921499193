import axiosClient, { checkoutPaymentServiceAxiosClient } from './base'
import { COUNTRY_CA, COUNTRY_US, CURRENCY_CA, CURRENCY_US } from '../constants'
import { isMobileDevice } from '../util/mobile'

const PAYMENT_INTENT_PATH = '/api/payment_intents'
const CAPTURE_PAYMENT_INTENT_PATH = (paymentIntentId) =>
	`/api/payment_intents/${paymentIntentId}/capture`

interface PaymentIntentArgs {
	amount: number
	customer?: any
	country?: string
	paymentMethodTypes?: string[]
	paymentMethodOptions?
	description?: string
	applicationFeeAmount?: number
	stripeAccount: string | undefined
	metadata?: any
	confirm?: any
	mandateData?: any
	paymentMethod?: any
}

export const createPaymentIntent = (
	{
		amount,
		customer,
		country = COUNTRY_US,
		paymentMethodTypes = ['card'],
		paymentMethodOptions,
		description,
		applicationFeeAmount,
		stripeAccount,
		metadata,
		confirm,
		mandateData,
		paymentMethod
	}: PaymentIntentArgs,
	options = {}
) =>
	axiosClient.post(PAYMENT_INTENT_PATH, {
		amount,
		customer,
		currency: country.toUpperCase() === COUNTRY_CA ? CURRENCY_CA : CURRENCY_US,
		payment_method_types: paymentMethodTypes,
		payment_method_options: paymentMethodOptions,
		description,
		application_fee_amount: applicationFeeAmount,
		on_behalf_of: stripeAccount,
		transfer_data: {
			destination: stripeAccount
		},
		metadata: {
			...metadata,
			createdFromMobile: isMobileDevice()
		},
		confirm,
		mandate_data: mandateData,
		payment_method: paymentMethod,
		...options
	})

export const capturePaymentIntent = (paymentIntentId) =>
	axiosClient.post(CAPTURE_PAYMENT_INTENT_PATH(paymentIntentId))

export const createSetupPaymentIntent = (payload) =>
	checkoutPaymentServiceAxiosClient.post('/setup-intent', payload)

export const updatePaymentIntent = (id, payload) =>
	axiosClient.post(`${PAYMENT_INTENT_PATH}/${id}`, payload)
