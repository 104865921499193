import { useRef, useState } from 'react'

interface IPopover {
	anchorRef: any
	open: boolean
	handleOpen: (e?) => void
	handleClose: (e?) => void
}

export const usePopover = (): IPopover => {
	const anchorRef = useRef(null)
	const [open, setOpen] = useState(false)

	const handleOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	return { anchorRef, open, handleOpen, handleClose }
}
