import axiosClient from './base'

const REIGSTER_URL = '/api/user/register'
const CREATE_STRIPE_ACCOUNT_LINK = (accountID) => `/api/accounts/${accountID}/links`
const UPDATE_ACCOUNT_INFO = (accountID) => `/api/accounts/${accountID}/update-account-info`
const EXTERNAL_ACCOUNT = (accountID) => `/api/accounts/${accountID}/external-accounts`
const LIST_STATES = '/api/places/states'
const UPDATE_EXTERNAL_ACCOUNT = (accountID, bankAccountID) =>
	`/api/accounts/${accountID}/external-accounts/${bankAccountID}`
const DELETE_EXTERNAL_ACCOUNT = (accountID, bankAccountID) =>
	`/api/accounts/${accountID}/external-accounts/${bankAccountID}`
export const register = (registerData) => axiosClient.post(REIGSTER_URL, registerData)

export const createStripeAccountLink = (
	accountID,
	{ collect, futureRequirements, refreshURL, returnURL }: ICreateAccountLinkParams
) =>
	axiosClient.post(CREATE_STRIPE_ACCOUNT_LINK(accountID), {
		collect,
		futureRequirements,
		refreshURL,
		returnURL
	})

export const updateAccountInfo = (accountID) => axiosClient.put(UPDATE_ACCOUNT_INFO(accountID))

export const createExternalBankAccount = (
	accountID,
	{ accountHolderName, accountHolderType, routingNumber, accountNumber, defaultAccount }
) =>
	axiosClient.post(EXTERNAL_ACCOUNT(accountID), {
		accountHolderName,
		accountHolderType,
		routingNumber,
		accountNumber,
		defaultAccount
	})

export const listStates = () => axiosClient.get(LIST_STATES)

export const listExternalBankAccounts = (accountID) => axiosClient.get(EXTERNAL_ACCOUNT(accountID))

export const updateExternalBankAccount = (
	accountID,
	bankAccountID,
	{ accountHolderName, accountHolderType, defaultAccount, batchTiming, meridiem, timezone }
) =>
	axiosClient.put(UPDATE_EXTERNAL_ACCOUNT(accountID, bankAccountID), {
		accountHolderName,
		accountHolderType,
		defaultAccount,
		customPayoutTimings: [batchTiming, meridiem, timezone].join(' ')
	})

export const deleteExternalBankAccount = (accountID, bankAccountID) =>
	axiosClient.delete(DELETE_EXTERNAL_ACCOUNT(accountID, bankAccountID))
