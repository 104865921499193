import { Navigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { selectUser } from '../selectors/auth'

const GuestGuard = ({ children }) => {
	const user = useSelector(selectUser)

	if (user) {
		return <Navigate to="/dashboard" />
	}

	return children
}

GuestGuard.propTypes = {
	children: PropTypes.node
}

export default GuestGuard
