import { createSvgIcon } from '@mui/material/utils'

const BubbleConversation = createSvgIcon(
	<svg
		version="1.1"
		id="Layer_1"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="25 25 94 94"
		xmlSpace="preserve"
		fill="currentColor"
	>
		<g>
			<path
				className="st0"
				d="M118.8,96.8L118.8,96.8l-5.4-15.6c2.6-5.3,4-11.1,4-17.1C116.6,42.6,98.5,25.8,77,26.6
c-20.2,0.8-36.4,16.8-37.5,36.9c-10.8,8.1-14.4,22.7-8.6,34.9l-3.7,10.7c-0.9,2.7,0.5,5.5,3.2,6.4c0.5,0.2,1.1,0.3,1.6,0.3
c0.6,0,1.1-0.1,1.7-0.3l10.7-3.7c3.8,1.8,7.9,2.7,12.1,2.7c9.1,0,17.6-4.3,22.9-11.7c5.6-0.2,11.1-1.5,16.1-3.9l15.6,5.4
c0.6,0.2,1.3,0.3,2,0.3c3.3,0,6-2.7,6-6C119.1,98.2,119,97.5,118.8,96.8z M56.5,109.2c-3.7,0-7.4-0.9-10.7-2.6
c-0.7-0.4-1.5-0.4-2.2-0.2l-11,3.8l3.8-11c0.2-0.7,0.2-1.5-0.2-2.2c-4.6-8.7-3.1-19.4,3.8-26.5c2.8,16.6,15.9,29.6,32.6,32.2
C68.3,106.9,62.5,109.2,56.5,109.2z M113.5,99.1c-0.1,0.2-0.4,0.2-0.5,0.1l-16.7-5.7c-0.3-0.1-0.6-0.1-0.9-0.1
c-0.5,0-0.9,0.1-1.3,0.3c-4.8,2.5-10.1,3.9-15.6,3.9C60,97.6,45,82.6,44.9,64.2c0-8.9,3.5-17.4,9.8-23.7c6.4-6.4,15.2-10,24.3-9.8
c18.5,0.3,33.2,15.6,32.9,34.1c-0.1,5.2-1.4,10.4-3.9,15c-0.3,0.7-0.4,1.5-0.2,2.2l5.7,16.7C113.6,98.8,113.6,99,113.5,99.1
L113.5,99.1z"
			/>
			<path
				className="st0"
				d="M95.1,49.5c-0.1,0-0.2,0-0.3,0H62c-1.5,0.1-2.7,1.4-2.6,2.9c0.1,1.4,1.2,2.5,2.6,2.6h32.7
c1.5,0.1,2.8-1.1,2.9-2.6C97.8,50.9,96.6,49.6,95.1,49.5z"
			/>
			<path
				className="st0"
				d="M95.1,60.8c-0.1,0-0.2,0-0.3,0H62c-1.5,0.1-2.7,1.4-2.6,2.9c0.1,1.4,1.2,2.5,2.6,2.6h32.7
c1.5,0.1,2.8-1.1,2.9-2.6C97.8,62.2,96.6,60.9,95.1,60.8z"
			/>
			<path
				className="st0"
				d="M82.5,72.1c-0.1,0-0.2,0-0.3,0H62c-1.5,0.1-2.7,1.4-2.6,2.9c0.1,1.4,1.2,2.5,2.6,2.6h20.1
c1.5,0.1,2.8-1.1,2.9-2.6C85.2,73.5,84,72.2,82.5,72.1z"
			/>
		</g>
	</svg>,
	'BubbleConversation'
)

export default BubbleConversation
