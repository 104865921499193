import axiosClient from './base'

export const getAppointmentRequests = ({
	practiceId,
	pageNumber,
	pageSize,
	name,
	status,
	statuses,
	types
}) =>
	axiosClient.get(`/api/practices/${practiceId}/appointment-requests`, {
		params: {
			pageNumber,
			pageSize,
			name,
			status,
			statuses,
			types
		}
	})

export const updateAppointmentRequest = (practiceId, apptRequestId, payload) =>
	axiosClient.put(`/api/practices/${practiceId}/appointment-requests/${apptRequestId}`, payload)

export const getAppointmentRequestsCount = ({ practiceId, status }) =>
	axiosClient.get(`/api/practices/${practiceId}/appointment-requests/count`, {
		params: {
			status
		}
	})

export const getAppointmentFeedback = ({ practiceId, pageNumber, pageSize }) =>
	axiosClient.get(`/api/practices/${practiceId}/appointment-feedback`, {
		params: {
			pageNumber,
			pageSize
		}
	})

export const getAppointmentForms = ({ practiceId }) =>
	axiosClient.get(`/api/practices/${practiceId}/appointment-forms`, {})

export const syncApptToPIMS = (practiceId, appointmentRequestId, payload) =>
	axiosClient.post(
		`/api/practices/${practiceId}/appointment-requests/${appointmentRequestId}/sync-to-pims`,
		payload
	)

export const createBlockOffSchedule = (practiceId, payload) =>
	axiosClient.post(`/api/practices/${practiceId}/blockoff-schedules`, payload)

export const fetchBlockOffSchedulesRequest = (practiceId) =>
	axiosClient.get(`/api/practices/${practiceId}/blockoff-schedules`)

export const updateBlockOffSchedule = (practiceId, blockoffScheduleId, payload) =>
	axiosClient.put(`/api/practices/${practiceId}/blockoff-schedules/${blockoffScheduleId}`, payload)
