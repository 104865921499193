import { SCRATCHPAY_CASH_BACKEND_CONFIG } from 'config'
import { cacheExchange, fetchExchange, debugExchange, mapExchange } from 'urql'
import { constructAuthHeader } from 'util/api-helpers'
import { GraphQLClientOptions } from 'gql/graphql-custom-types'
import { RequestLevel } from '../constants'

const gqlEndpoint = (requestLevel: string) => {
	const { baseURL } = SCRATCHPAY_CASH_BACKEND_CONFIG
	if (requestLevel === RequestLevel.ADMIN) {
		return `${baseURL}/admin/graphql`
	}

	if (requestLevel === RequestLevel.MANAGER) {
		return `${baseURL}/manager/graphql`
	}

	if (requestLevel === RequestLevel.SCRATCHADMIN) {
		return `${baseURL}/scratch/graphql`
	}

	return `${baseURL}/graphql`
}

export const gqlClientOptions = (options: GraphQLClientOptions) => ({
	url: gqlEndpoint(options.requestLevel),
	exchanges: [
		debugExchange, // remove duplicate requests
		cacheExchange,
		// Add auth header to all requests
		mapExchange({
			onOperation(operation) {
				// eslint-disable-next-line no-async-promise-executor
				return new Promise(async (resolve, reject) => {
					const authHeader = await constructAuthHeader()
					if (!authHeader) {
						reject(new Error('No auth header found'))
					}
					const headers = {
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore fetchOptions will have a headers property
						...operation.context.fetchOptions?.headers,
						Authorization: authHeader
					}
					if (options.practiceId) {
						headers.practiceId = options.practiceId
					}
					if (options.consolidatorId) {
						headers.consolidatorId = options.consolidatorId
					}

					operation.context.fetchOptions = {
						...operation.context.fetchOptions,
						headers
					}
					resolve(operation)
				})
			}
		}),
		fetchExchange
	]
})
