import { isEmpty } from 'lodash'

class CustomFields {
	private customizedFields

	async init() {
		this.customizedFields = null
	}

	get customFields() {
		return this.customizedFields
	}

	set customFields(fields) {
		this.customizedFields = fields
	}

	getCustomField(key, defaultField) {
		const keys = key.split('.')
		let customFieldSearch = this.customizedFields

		if (isEmpty(customFieldSearch)) {
			return defaultField
		}

		for (const path of keys) {
			if (!Object.prototype.hasOwnProperty.call(customFieldSearch, path)) {
				return defaultField
			}
			// nosemgrep prototype-pollution-loop
			customFieldSearch = customFieldSearch[path]
		}

		return customFieldSearch || defaultField
	}
}
export default new CustomFields()
