import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getAppointmentRequestsCount } from '../apis/appointments'

const initialState = {
	requestedAppointmentsCount: 0,
	status: 'idle'
}

const slice = createSlice({
	name: 'appointmentRequests',
	initialState,
	reducers: {},
	extraReducers(builder) {
		builder
			.addCase(fetchAppointmentRequestsCount.pending, (state) => {
				state.status = 'loading'
			})
			.addCase(fetchAppointmentRequestsCount.fulfilled, (state, action) => {
				state.status = 'succeeded'
				state.requestedAppointmentsCount = action.payload
			})
			.addCase(fetchAppointmentRequestsCount.rejected, (state) => {
				state.status = 'failed'
			})
	}
})

export const fetchAppointmentRequestsCount = createAsyncThunk(
	'appointmentRequests/count',
	async (payload: any) => {
		const practiceId = payload?.practiceId
		const response = await getAppointmentRequestsCount({ practiceId, status: 'REQUESTED' })
		return response.data.totalCount
	}
)

export const { reducer } = slice

export default slice
