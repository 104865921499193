import * as Sentry from '@sentry/react'
import { ENV, SENTRY_CONFIG } from '../config'
import { ENV_PRODUCTION } from '../constants'

let isSentryInitialized = false

export const initSentry = () => {
	if (ENV === ENV_PRODUCTION) {
		isSentryInitialized = true
		Sentry.init({
			dsn: SENTRY_CONFIG.dsn,
			environment: ENV,
			// Prevent unnecessary ResizeObserver error
			// reporting on Sentry, since it's an harmless warning - DASH-2988
			ignoreErrors: [
				'ResizeObserver loop limit exceeded',
				'ResizeObserver loop completed with undelivered notifications'
			]
			// Uncomment this to enable tracing
			// integrations: [new Integrations.BrowserTracing()],
			// Set tracesSampleRate to 1.0 to capture 100%
			// of transactions for performance monitoring.
			// We recommend adjusting this value in production
			// tracesSampleRate: 1.0
		})
	}
}

export const captureException = (error) => {
	if (isSentryInitialized && Sentry.captureException) {
		Sentry.captureException(error)
	}
}

export const captureMessage = (error) => {
	if (isSentryInitialized && Sentry.captureMessage) {
		Sentry.captureMessage(error)
	}
}

export const configureScopeWithUserId = (userId, email) => {
	if (isSentryInitialized) {
		Sentry.configureScope((scope) => {
			scope.setUser({ id: userId, email })
		})
	}
}
