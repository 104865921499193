import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { updatePractice, useManageNotifications, useDisputesByEvidenceNeeded } from '../util/db'
import { fetchPostJSON } from '../util/api-helpers'
import { selectContext } from '../selectors/auth'
import { STATUS_SUCCESS } from '../constants'
import CustomizedLabels from '../util/CustomizedLabels'
import customFields from '../util/customFields'
import { isScratchAdminUser } from '../util/user'
import { fetchAppointmentRequestsCount } from '../slices/apptRequests'
import { AppDispatch } from '../store'
import { usePractices, getConsolidator } from '../util/unified'

export interface PracticeContext {
	currentPracticeObject: IPractice // IPractice
	practiceID?: string
	terminalLocation?: string
	userRolePractice?: UserRolePractice
	clinicName?: string
	notificationCnt?: number
	disputesNotificationCount?: number
	currentConsolidator?: IConsolidator
	currentUserConsolidator?: IConsolidator
	dashboardUserId?: number

	// TODO: Should these be on the Context.Provider below?
	setCurrentPracticeObject?: any
	setPracticeID?: any
}

const CurrentPracticeContext = createContext<PracticeContext>({ currentPracticeObject: {} })

export function useCurrentPractice() {
	return useContext(CurrentPracticeContext)
}

export function PracticeProvider({ children }) {
	const context = useSelector(selectContext)
	const user = context?.user
	const dispatch = useDispatch<AppDispatch>()

	const primaryPractice =
		user?.primaryClinic ||
		user?.stripeClinicId ||
		user?.stripeClinicID ||
		(user?.financingStatus === 'active' && user?.practices?.[0]?.practice?.id)
	const [practiceID, setPracticeID] = useState<string>()
	const [clinicName, setClinicName] = useState()
	const [terminalLocation, setTerminalLocation] = useState()
	const [currentPracticeObject, setCurrentPracticeObject] = useState<IPractice>({})
	const [notificationCnt, setNotificationCnt] = useState()
	const [disputesNotificationCount, setDisputesNotificationCount] = useState<number | undefined>(0)
	const [currentConsolidator, setCurrentConsolidator] = useState<IConsolidator | undefined>(
		undefined
	)
	const [currentUserConsolidator, setCurrentUserConsolidator] = useState<IConsolidator | undefined>(
		undefined
	)
	const [userRolePractice, setUserRolePractice] = useState({
		staff: false,
		hospitalManager: false,
		regionalManager: false
	})
	const [dashboardUserId, setDashboarUserId] = useState<number>()

	const { data: practicesData, error: practicesError } = usePractices(context, practiceID)

	if (practicesError) {
		console.error(practicesError)
	}

	useEffect(() => {
		setPracticeID(primaryPractice)
	}, [primaryPractice])

	// updates currentPracticeObject when changing locations
	useEffect(() => {
		// determines user's role at currentPracticeObject
		const handleUserRolePractice = () => {
			if (!user || !user.uid || !currentPracticeObject) {
				return
			}
			setUserRolePractice({
				staff:
					currentPracticeObject?.staffUsers?.some((staffUser) => staffUser === user.uid) || false,
				hospitalManager:
					currentPracticeObject?.hmUsers?.some((hmUser) => hmUser === user.uid) || false,
				regionalManager: currentPracticeObject?.regionalManagerId === user.uid || false
			})
		}

		async function loadConsolidatorData() {
			const consolidatorData =
				practicesData?.consolidatorId &&
				(await getConsolidator(context, practicesData.consolidatorId))
			if (consolidatorData && consolidatorData.exists()) {
				const data = consolidatorData.data()
				setCurrentConsolidator(data)
				CustomizedLabels.customLabels = data?.customization
				customFields.customFields = data?.customFields
				if (user?.consolidatorId?.toString() === practicesData?.consolidatorId?.toString()) {
					setCurrentUserConsolidator(data)
				}
			} else {
				setCurrentConsolidator(undefined)
				CustomizedLabels.customLabels = null
				customFields.customFields = null
			}
			if (!user || !user.consolidatorId) {
				return
			}

			if (user?.consolidatorId !== practicesData?.consolidatorId && isScratchAdminUser(user)) {
				const userConsolidatorData = await getConsolidator(context, user.consolidatorId)
				if (userConsolidatorData && userConsolidatorData.exists()) {
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore: if exists() is true, data() should not be undefined
					const data = userConsolidatorData.data()
					CustomizedLabels.customLabels = data?.customization
					setCurrentUserConsolidator(data as IConsolidator)
				} else {
					setCurrentUserConsolidator(undefined)
				}
			}
		}

		if (!user) {
			setPracticeID(undefined)
			setCurrentPracticeObject({})
			setTerminalLocation(undefined)
			handleUserRolePractice()
			setCurrentConsolidator(undefined)
			return
		}
		if (practicesData) {
			// Set the consolidator data before the practice object so you don't get any flashing
			setCurrentPracticeObject(practicesData)
			setTerminalLocation(practicesData.terminalLocation)
			setClinicName(practicesData.clinicName)
			handleUserRolePractice()
			loadConsolidatorData()
			setDashboarUserId(user.dashboardUserId)
			if (practicesData?.settings?.isAppointmentRequestsEnabled) {
				dispatch(fetchAppointmentRequestsCount({ practiceId: practicesData.id }))
				// Update Appointment Requests count every 60 seconds
				const intervalId = setInterval(() => {
					dispatch(fetchAppointmentRequestsCount({ practiceId: practicesData.id }))
				}, 60000)
				return () => clearInterval(intervalId)
			}
		}
		// eslint wants currentPracticeobject and terminal location in the dpendencies, but that causes maximum updates.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [practicesData, user])

	// creates terminal location if no terminal
	useEffect(() => {
		try {
			if (!user || !currentPracticeObject) {
				// console.log('do not create new location');
				return
			}
			// console.log(terminalLocation);
			if (
				user &&
				currentPracticeObject?.cuid &&
				currentPracticeObject?.terminalLocation === undefined &&
				practicesData?.terminalLocation === undefined &&
				terminalLocation === undefined
			) {
				// console.log('create a new terminal location');
				const fetch = async () => {
					// if (!currentPracticeObject?.clinicAddress || !currentPracticeObject?.clinicCountry) {
					//   console.log('clinic needs an address, canceling terminal location creation');
					//   return;
					// }
					const responseTerminalLocation = await fetchPostJSON(
						'/api/create_stripe_terminal_location',
						{
							display_name: currentPracticeObject?.cuid,
							'address[line1]': currentPracticeObject?.clinicAddress,
							'address[city]': currentPracticeObject?.clinicCity,
							'address[country]': currentPracticeObject?.clinicCountry,
							'address[postal_code]': currentPracticeObject?.clinicZip,
							'address[state]': currentPracticeObject?.clinicState,
							'metadata[connected_account]': currentPracticeObject?.cuid
						}
					)
					// console.log('Create terminalLocation RESPONSE', responseTerminalLocation);
					const createTerminalLocation = await responseTerminalLocation.id
					updatePractice(currentPracticeObject?.cuid, {
						terminalLocation: createTerminalLocation
					})
					// console.log('terminalLocation Added to Practice');
				}
				fetch().catch((e) => e)
			}
		} catch (err) {
			// console.log(err);
		}
	}, [currentPracticeObject, user])

	const {
		status: notificationStatus,
		data: notifications,
		error: notificationsError
	} = useManageNotifications(currentPracticeObject?.stripeAccountId)

	if (notificationsError) {
		console.error(notificationsError)
	}

	const {
		status: disputesNotificationsStatus,
		data: disputesNotifications,
		error: disputesNotificationsError
	} = useDisputesByEvidenceNeeded(currentPracticeObject?.stripeAccountId)

	if (disputesNotificationsError) {
		console.error(disputesNotificationsError)
	}

	useEffect(() => {
		if (disputesNotificationsStatus === STATUS_SUCCESS) {
			setDisputesNotificationCount(disputesNotifications?.length)
		}
		if (notificationStatus === STATUS_SUCCESS) {
			setNotificationCnt(notifications?.length)
		}
	}, [currentPracticeObject, user, notifications, disputesNotifications])

	const contexts = useMemo(
		() => ({
			currentPracticeObject,
			setCurrentPracticeObject,
			practiceID,
			setPracticeID,
			terminalLocation,
			userRolePractice,
			clinicName,
			notificationCnt,
			disputesNotificationCount,
			currentConsolidator,
			currentUserConsolidator,
			dashboardUserId
		}),
		[
			currentPracticeObject,
			practiceID,
			terminalLocation,
			userRolePractice,
			notificationCnt,
			currentConsolidator,
			currentUserConsolidator,
			user
		]
	)

	return (
		<CurrentPracticeContext.Provider value={contexts}>{children}</CurrentPracticeContext.Provider>
	)
}

PracticeProvider.propTypes = {
	children: PropTypes.node.isRequired
}

export default PracticeProvider
