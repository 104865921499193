import { Navigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { selectUser } from '../selectors/auth'

const NoPracticeGuard = ({ children }) => {
	const user = useSelector(selectUser)

	if (user && user.primaryClinic === 'confused') {
		return <Navigate to="/dashboard" />
	}

	return children
}

NoPracticeGuard.propTypes = {
	children: PropTypes.node
}

export default NoPracticeGuard
