import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { Box } from '@mui/material'

import PracticeRequiredInfoAlertBox from './PracticeRequiredInfoAlertBox'
import { PracticeFutureRequiredInfoAlertBox } from './PracticeFutureRequiredInfoAlertBox'

export const PracticeRequirementsBox = () => {
	const location = useLocation()

	/**
	 * Hide the Stripe Requirements banner
	 * when the URL is for financing or communications
	 * or when the URL is the account required info page
	 */
	const shouldHideStripeRequirements = useMemo(
		() =>
			location.pathname.startsWith('/financing') ||
			location.pathname.startsWith('/communication') ||
			[
				'/dashboard/chat/new',
				'/dashboard/account/practice-required-info',
				'/settings/required-info'
			].includes(location.pathname),
		[location.pathname]
	)

	if (shouldHideStripeRequirements) {
		return null
	}

	return (
		<Box
			display="flex"
			flexDirection="column"
			gap={1}
			sx={{
				'> div:first-child': {
					marginTop: 6
				},
				'> div:last-child': {
					marginBottom: 1
				}
			}}
		>
			<PracticeRequiredInfoAlertBox />
			<PracticeFutureRequiredInfoAlertBox />
		</Box>
	)
}
