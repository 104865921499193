import { gql } from 'urql'
import { Query } from './generated-types/custom-graphql'

export const PRACTICE_LIST_QUERY = gql<Query>(`
  query GetPractices ($skip: Int, $take: Int, $orderBy: [PracticeOrderByInput!], $where: PracticeWhereInput) {
    practices (orderBy: $orderBy, skip: $skip, take: $take, where: $where) {
      id
      stripeAccountId
      name
      city
      state
      address
      zip
    }
  }
`)

export const ALL_PRACTICE_LIST_WITH_COUNT_QUERY = gql<Query>(`
  query GetAllPracticesCount ($skip: Int, $take: Int, $orderBy: [PracticeOrderByInput!], $where: PracticeWhereInput) {
    practicesWithCount (orderBy: $orderBy, skip: $skip, take: $take, where: $where) {
      items {
        id
        stripeAccountId
        consolidatorId
        name
        city
        state
        address
        zip
        vetClinicId
        settings
        integrationId
        hospitalManagerEmail
        products
        routingNumber
        accountNumber
        logoUrl
        activeTerminalsCount
        totalTerminalsCount
        currentPims
        timezone
				isCustomPayoutEnabled
        consolidator {
          id
          featureFlags
        }
      }
      total
    }
  }
`)

export const ALL_PRACTICE_LIST_QUERY = gql<Query>(`
  query GetAllPractices ($skip: Int, $take: Int, $orderBy: [PracticeOrderByInput!], $where: PracticeWhereInput) {
    practices (orderBy: $orderBy, skip: $skip, take: $take, where: $where) {
      id
      stripeAccountId
      consolidatorId
      name
      city
      state
      address
      zip
      vetClinicId
      settings
      integrationId
      hospitalManagerEmail
      products
      routingNumber
      accountNumber
      logoUrl
      activeTerminalsCount
      totalTerminalsCount
      currentPims
      timezone
      isCustomPayoutEnabled
      customPayoutSettings
      consolidator {
        id
        featureFlags
      }
    }
  }
`)

// TODO: this should just be the context query directly so the counts are done all at once.
export const PRACTICE_CONTEXT_QUERY = gql<Query>(`
  query GetPracticeContext ($skip: Int, $take: Int, $orderBy: [PracticeOrderByInput!], $where: PracticeWhereInput) {
    practices (orderBy: $orderBy, skip: $skip, take: $take, where: $where) {
      id
      stripeAccountId
      name
      city
      address
      state
      vetClinicId
      terminalLocation
      consolidatorId
      timezone
      locale
      consolidatorReferenceCode
      consolidatorReferenceCode2
      surchargeEnabled
      surchargeFeePct
      settings
      financingStatus
      isDashboardEnabled
      isUnderwritten
      originationFlags
      underwrittingStatus
      regionalManagerId
			isCustomPayoutEnabled
      consolidator {
        id
        featureFlags
        financingFlags
        customization
        customFields
        timezone
      }
    }
  }
`)
