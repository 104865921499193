import { gql } from 'urql'
import { Query, Mutation } from './generated-types/custom-graphql'

export const LIST_REFUND_REQUESTS = gql<Query>(`
  query ListRefundRequests ($skip: Int, $take: Int, $orderBy: [RefundRequestOrderByInput!], $where: RefundRequestWhereInput, $all: Boolean) {
    listRefundRequests(skip: $skip, take: $take, orderBy: $orderBy, where: $where, all: $all) {
      total
      skip
      take
      items {
        id
        consolidatorId
        paymentIntentId
        paymentIntent {
          metadata
        }
        originalPaymentAmount
        originalPaymentProcessedAt
        requestedRefundAmount
        requestedById
        requestedByEmail
        requestedBy {
          name
          displayName
        }
        approverRole
        notesRequestor
        status
        pimsClientId
        writebackPimsClientId
        createdAt
        updatedAt
        practice {
          id
          name
          currentPims
        }
        chargeId
        notesApprover
        resolvedAt
        resolvedById
      }
    }
  }
`)

export const CREATE_REFUND_REQUEST = gql<Mutation>(`
  mutation CreateRefundRequest(
    $paymentIntentId: String!,
    $pimsClientId: String,
    $notes: String,
    $requestedRefundAmount: Float!,
    $chargeId: String,
    $writebackPimsClientId: String
    ) {
      upsertRefundRequest(
      paymentIntentId: $paymentIntentId,
      pimsClientId: $pimsClientId,
      notes: $notes,
      chargeId: $chargeId,
      requestedRefundAmount: $requestedRefundAmount,
      writebackPimsClientId: $writebackPimsClientId
      ) {
      id,
      status,
      requestedRefundAmount
    }
  }
`)

export const UPDATE_REFUND_REQUEST = gql<Mutation>(`
  mutation UpdateRefundRequest(
    $id: ID!,
    $status: String!,
    $notes: String
    ) {
      upsertRefundRequest(
      id: $id,
      status: $status,
      notes: $notes,
      ) {
        id
        consolidatorId
        paymentIntentId
        originalPaymentAmount
        originalPaymentProcessedAt
        requestedRefundAmount
        requestedById
        requestedByEmail
        approverRole
        notesRequestor
        notesApprover
        status
        pimsClientId
        writebackPimsClientId
        createdAt
        updatedAt
        chargeId
        notesApprover
        resolvedAt
        resolvedById
    }
  }
`)
