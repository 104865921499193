import { useState } from 'react'
import { Outlet } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import { FOOTER_HEIGHT, HEADER_HEIGHT } from '../../constants'
import DashboardNavbar from './DashboardNavbar'
import DashboardSidebar from './DashboardSidebar'
import DashboardFooter from './DashboardFooter'
import { PracticeRequirementsBox } from './practices/PracticeRequirementsBox'

const DashboardLayoutContainer = styled('div')({
	display: 'flex',
	flex: '1 1 auto',
	overflow: 'hidden'
})

const DashboardLayoutContent = styled('div')({
	flex: '1 1 auto',
	height: '100%',
	overflow: 'auto',
	position: 'relative',
	WebkitOverflowScrolling: 'touch'
})

const DashboardNavbarPlaceholder = styled('div')({
	height: '64px'
})

const DashboardLayout = () => {
	const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState(false)

	return (
		<div>
			<DashboardNavbar onSidebarMobileOpen={() => setIsSidebarMobileOpen(true)} />
			<DashboardNavbarPlaceholder />
			<Box
				display="flex"
				flexDirection="row"
				flexGrow={1}
				style={{ height: `calc(100vh - ${HEADER_HEIGHT} - ${FOOTER_HEIGHT})` }}
			>
				<DashboardSidebar
					onMobileClose={() => setIsSidebarMobileOpen(false)}
					openMobile={isSidebarMobileOpen}
				/>
				<DashboardLayoutContainer>
					<DashboardLayoutContent>
						<PracticeRequirementsBox />
						<Outlet />
					</DashboardLayoutContent>
				</DashboardLayoutContainer>
			</Box>
			<DashboardFooter />
		</div>
	)
}

export default DashboardLayout
