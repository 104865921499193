import { combineReducers } from '@reduxjs/toolkit'
import { reducer as practicesReducer } from '../slices/practices'
import { reducer as chatReducer } from '../slices/chat'
import { reducer as authReducer } from '../slices/auth'
import { reducer as usersReducer } from '../slices/users'
import { reducer as practiceInstanceReducer } from '../slices/practiceInstance'
import { reducer as appointmentRequestsReducer } from '../slices/apptRequests'
import { reducer as oneTimeEmailReducer } from '../slices/oneTimeEmail'
import { reducer as actionNeededReducer } from '../slices/actionNeeded'

const rootReducer = combineReducers({
	practices: practicesReducer,
	chat: chatReducer,
	auth: authReducer,
	users: usersReducer,
	practiceInstance: practiceInstanceReducer,
	appointmentRequests: appointmentRequestsReducer,
	oneTimeEmail: oneTimeEmailReducer,
	actionNeeded: actionNeededReducer
})

export default rootReducer
