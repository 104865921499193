import { useMemo } from 'react'
import * as Yup from 'yup'
import { isNumericString } from 'util/string'
import { useCurrentPractice } from '../../../../contexts/PracticeContext'
import { validateInvoiceIsNumber, shouldRequireInvoiceNumber } from '../../../../util/consolidator'

/**
 * Hook `useInvoiceNumberValidator`
 *
 * This custom hook provides utilities for validating invoice numbers based on the current user's consolidator settings.
 * It returns an object containing two functions - `isInvoiceNumberValid` and `getYupInvoiceNumberSchema`.
 *
 * @returns {Object} - Object containing validation functions.
 * @returns {function} `isInvoiceNumberValid` - A function that takes an invoice number as a parameter and returns a boolean indicating
 *                     whether the invoice number is valid according to the consolidator settings.
 * @returns {function} `getYupInvoiceNumberSchema` - A function that takes an optional base Yup.StringSchema (default is Yup.string())
 *                     and returns a Yup.StringSchema that is configured based on the consolidator settings.
 *                     This schema can be used for form validation.
 */
const useInvoiceNumberValidator = () => {
	const { currentUserConsolidator } = useCurrentPractice()
	const requireInvoiceNumber = useMemo(
		() => shouldRequireInvoiceNumber(currentUserConsolidator),
		[currentUserConsolidator]
	)
	const shouldValidateInvoiceisNumber = useMemo(
		() => validateInvoiceIsNumber(currentUserConsolidator),
		[currentUserConsolidator]
	)

	const isInvoiceNumberValid = (invoiceNumber) => {
		if (shouldValidateInvoiceisNumber && invoiceNumber?.length > 0)
			return isNumericString(invoiceNumber)
		return true
	}

	const getYupInvoiceNumberSchema = (
		baseSchema: Yup.StringSchema = Yup.string()
	): Yup.StringSchema => {
		let invoiceNumberSchema = baseSchema
		if (shouldValidateInvoiceisNumber) {
			invoiceNumberSchema = invoiceNumberSchema
				.trim()
				.matches(/^\d+$/, 'Only numbers can be entered')
		}
		if (requireInvoiceNumber) {
			invoiceNumberSchema = invoiceNumberSchema.required('Invoice Number is Required')
		}
		return invoiceNumberSchema
	}

	return { isInvoiceNumberValid, getYupInvoiceNumberSchema }
}

export default useInvoiceNumberValidator
