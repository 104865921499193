import { createContext, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { THEMES } from '../constants'

type SettingsType = {
	compact: boolean
	direction: 'ltr' | 'rtl'
	responsiveFontSizes: boolean
	roundedCorners: boolean
	theme: (typeof THEMES)[keyof typeof THEMES]
}

type SettingsContextType = {
	settings: SettingsType
	saveSettings: (settings?: SettingsType) => void
}

const initialSettings: SettingsType = {
	compact: true,
	direction: 'ltr',
	responsiveFontSizes: true,
	roundedCorners: true,
	theme: THEMES.LIGHT
}

export const restoreSettings = () => {
	let settings: any = null

	try {
		const storedData = window.localStorage.getItem('settings')

		if (storedData) {
			settings = JSON.parse(storedData)
		} else {
			settings = {
				compact: true,
				direction: 'ltr',
				responsiveFontSizes: true,
				roundedCorners: true,
				theme: window.matchMedia('(prefers-color-scheme: dark)').matches
					? THEMES.DARK
					: THEMES.LIGHT
			}
		}
	} catch (err) {
		console.error(err)
	}

	return settings
}

export const storeSettings = (settings) => {
	window.localStorage.setItem('settings', JSON.stringify(settings))
}

const SettingsContext = createContext<SettingsContextType>({
	settings: initialSettings,
	saveSettings: () => {
		void 0
	}
})

export const SettingsProvider = (props) => {
	const { children } = props
	const [settings, setSettings] = useState(initialSettings)

	useEffect(() => {
		const restoredSettings = restoreSettings()

		if (restoredSettings) {
			setSettings(restoredSettings)
		}
	}, [])

	const saveSettings = (updatedSettings) => {
		setSettings(updatedSettings)
		storeSettings(updatedSettings)
	}

	const context = useMemo(
		() => ({
			settings,
			saveSettings
		}),
		[settings]
	)

	return <SettingsContext.Provider value={context}>{children}</SettingsContext.Provider>
}

SettingsProvider.propTypes = {
	children: PropTypes.node.isRequired
}

export const SettingsConsumer = SettingsContext.Consumer

export default SettingsContext
