import toast from 'react-hot-toast'
import { isYupError, getYupErrorMessage, getAxiosErrorMessage } from './util'
import { errorToastStyle } from '../theme'

const toastErrorOptions = {
	duration: 10000,
	style: {
		...errorToastStyle
	}
}

const errorToast = <T>(error, toastOptions = {}) => {
	const options = {
		...toastErrorOptions,
		...toastOptions
	}
	if (typeof error === 'string') return toast(error, options)

	const errMsg = isYupError(error) ? getYupErrorMessage(error) : getAxiosErrorMessage<T>(error)
	return toast(errMsg, options)
}

export default errorToast
