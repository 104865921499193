export const notificationTypes = {
	AR_PAYMENT_RECEIVED: {
		title: 'New Accounts Receivable Payment Received!',
		content:
			'A customer has made a payment that needs to be written back to your practice management system.'
	},
	FAILED_CHECK: {
		title: 'Failed Check Deposit',
		content: 'A check deposit has been declined and needs your attention.'
	},
	FAILED_LOCKBOX: {
		title: 'Failed Lockbox Deposit',
		content: 'A lockbox check has been declined and needs your attention.'
	},
	MOBILE_PAYMENT: {
		title: 'Mobile Payment',
		content: 'Add mobile payment to PIMS.'
	}
}
