import { createSlice } from '@reduxjs/toolkit'

// TODO TSC any
const initialState: any = {
	userConnectionState: null,
	practiceConnectionState: null,
	conversationList: [],
	activeConversationSid: null,
	messagesList: {},
	unreadMessages: {},
	participants: {},
	attachments: {},
	conversationsMap: {},
	recipients: [],
	searchConversationsText: '',
	chatThreadsLoading: true
}

const slice = createSlice({
	name: 'chat',
	initialState,
	reducers: {
		resetChatOnNewPractice(state) {
			state.conversationList = []
			state.messagesList = {}
			state.unreadMessages = {}
			state.participants = {}
			state.attachments = {}
			state.searchConversationsText = ''
			state.chatThreadsLoading = true
		},
		addAttachment(state, action) {
			const { conversationSid, messageIndex, attachment } = action.payload
			state.attachments = {
				...state.attachments,
				[conversationSid]: {
					...(state.attachments[conversationSid] || {}),
					[messageIndex]: attachment
				}
			}
		},
		updateUnreadMessages(state, action) {
			const { conversationSid, count } = action.payload
			state.unreadMessages = { ...state.unreadMessages, [conversationSid]: count ?? 0 }
		},
		deleteConvo(state, action) {
			const conversation = action.payload
			state.conversationList = [
				...state.conversationList.filter((it) => it?.sid !== conversation?.sid)
			]
			delete state.messagesList[conversation?.sid]
			delete state.unreadMessages[conversation?.sid]
			delete state.participants[conversation?.sid]
			delete state.attachments[conversation?.sid]
			delete state.conversationsMap[conversation?.sid]
		},
		updateConvoList(state, action) {
			const conversation = action.payload
			const removeConvo = [...state.conversationList.filter((it) => it.sid !== conversation.sid)]
			const addConvo = [...removeConvo, conversation]
			state.conversationList = addConvo.sort(
				(a, b) =>
					(b.lastMessage?.dateCreated.getTime() || b.dateUpdated.getTime()) -
					(a.lastMessage?.dateCreated.getTime() || a.dateUpdated.getTime())
			)
		},
		setConvoList(state, action) {
			state.chatThreadsLoading = false
			const conversation = action.payload
			if (!state.conversationsMap[conversation.sid]) {
				const list = [...state.conversationList, conversation]
				state.conversationsMap[conversation.sid] = conversation
				state.conversationList = list.sort(
					(a, b) =>
						(b.lastMessage?.dateCreated || b.dateUpdated) -
						(a.lastMessage?.dateCreated || a.dateUpdated)
				)
				console.log('conversations count', state.conversationList.length)
			}
		},
		setConversationsList(state, action) {
			state.chatThreadsLoading = false
			state.conversationList = action.payload.sort(
				(a, b) =>
					(b.lastMessage?.dateCreated || b.dateUpdated) -
					(a.lastMessage?.dateCreated || a.dateUpdated)
			)
			for (const c of state.conversationList) {
				state.conversationsMap[c.sid] = c
			}
			console.log('conversations count', state.conversationList.length)
		},
		updateParticipants(state, action) {
			const { conversation, participants } = action.payload
			state.participants = { ...state.participants, [conversation.sid]: participants }
		},
		updatePracticeConnectionState(state, action) {
			state.practiceConnectionState = action.payload
		},
		updateUserConnectionState(state, action) {
			state.userConnectionState = action.payload
		},
		addMessages(state, action) {
			const { conversationSid, messagesToAdd, conversation } = action.payload
			const existingMessages = state.messagesList[conversationSid] ?? []

			const filteredExistingMessages = existingMessages.filter(
				(message) =>
					!messagesToAdd.find(
						(value) =>
							value.body === message.body &&
							value.author === message.author &&
							value.media?.filename === message.media?.filename &&
							value.media?.size === message.media?.size &&
							(message.index === -1 || value.index === message.index)
					)
			)
			const messagesUnique = [...filteredExistingMessages, ...messagesToAdd]
			const sortedMessages = messagesUnique.sort(
				(a, b) => a.dateCreated.getTime() - b.dateCreated.getTime()
			)
			state.messagesList = { ...state.messagesList, [conversationSid]: sortedMessages }
			const { conversationList } = state
			if (!state.conversationsMap[conversationSid]) {
				conversationList.push(conversation)
				state.conversationsMap[conversationSid] = conversation
			}
			state.conversationList = conversationList.sort(
				(a, b) =>
					(b.lastMessage?.dateCreated.getTime() || b.dateUpdated.getTime()) -
					(a.lastMessage?.dateCreated.getTime() || a.dateUpdated.getTime())
			)
		},
		resetActiveConversationSid(state) {
			state.activeConversationSid = null
		},
		getParticipantsByConversation(state, action) {
			const { conversation, participants } = action.payload
			state.participants = { ...state.participants, [conversation.sid]: participants }
		},
		addRecipient(state, action) {
			const { recipient } = action.payload
			const exists = state.recipients.find((_recipient) => _recipient.ID === recipient.ID)
			if (!exists) {
				state.recipients.push(recipient)
			}
		},
		removeRecipient(state, action) {
			const { recipientId } = action.payload
			state.recipients = state.recipients.filter((recipient) => recipient.ID !== recipientId)
		},
		removeAllRecipients(state) {
			state.recipients = []
		},
		setActiveConversationSid(state, action) {
			const activate = action.payload
			state.activeConversationSid = activate
		},
		setSearchConversationsText(state, action) {
			state.searchConversationsText = action.payload
		}
	}
})

export const { reducer } = slice

export const {
	setSearchConversationsText,
	setConvoList,
	updateParticipants,
	addMessages,
	updateUnreadMessages,
	deleteConvo,
	setConversationsList
} = slice.actions

export const resetActiveConversationSid = () => (dispatch) => {
	dispatch(slice.actions.resetActiveConversationSid())
}

export const getParticipantsByConversation = (conversation) => async (dispatch) => {
	const participants = await conversation.getParticipants()
	dispatch(slice.actions.getParticipantsByConversation({ conversation, participants }))
}

export const addRecipient = (recipient) => (dispatch) => {
	dispatch(slice.actions.addRecipient({ recipient }))
}

export const removeRecipient = (recipientId) => (dispatch) => {
	dispatch(slice.actions.removeRecipient({ recipientId }))
}

export const removeAllRecipients = () => (dispatch) => {
	dispatch(slice.actions.removeAllRecipients())
}

export const setActiveConversationSid = (conversationSid) => (dispatch) => {
	dispatch(slice.actions.setActiveConversationSid(conversationSid))
}

export const updatePracticeConnectionState = (practiceConnectionState) => async (dispatch) => {
	dispatch(slice.actions.updatePracticeConnectionState(practiceConnectionState))
}

export const updateUserConnectionState = (userConnectionState) => async (dispatch) => {
	dispatch(slice.actions.updateUserConnectionState(userConnectionState))
}

export const updateConvoList = (conversation) => async (dispatch) => {
	dispatch(slice.actions.updateConvoList(conversation))
}

// TODO TSC: these look to be wrong.
export const updateLastReadMessageIndex = (conversation) => async (dispatch) => {
	if (conversation?.lastMessage?.index || conversation?.lastMessage?.index === 0) {
		await conversation.updateLastReadMessageIndex(conversation?.lastMessage?.index)
	}
	dispatch(slice.actions.updateUnreadMessages({ conversationSid: conversation.sid, count: 0 }))
}

export const addAttachment = (conversationSid, messageIndex, attachment) => (dispatch) => {
	dispatch(slice.actions.addAttachment({ conversationSid, messageIndex, attachment }))
}

export const resetChatOnNewPractice = () => (dispatch) => {
	dispatch(slice.actions.resetChatOnNewPractice())
}

export default slice
