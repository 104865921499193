import { Suspense } from 'react'
import ResourceLoading from './resource-loading'

export const Loadable = (Component) => (props) => (
	<Suspense fallback={<ResourceLoading />}>
		<Component {...props} />
	</Suspense>
)

export default Loadable
