import { useMemo } from 'react'
import { styled } from '@mui/material/styles'
import { Box, Link } from '@mui/material'
import { useSelector } from 'react-redux'
import * as darkColors from '../../theme/dark'
import GiftIcon from '../../icons/Gift'
import { FOOTER_HEIGHT } from '../../constants'
import { selectUser } from '../../selectors/auth'
import { CURRENT_VERSION } from '../../config'
import { useCurrentPractice } from 'contexts/PracticeContext'

const DashboardFooterContainer = styled('footer')(({ theme }) => ({
	height: FOOTER_HEIGHT,
	padding: '15px 20px',
	background: theme.palette.brand.midnight,
	color: darkColors.textColor.secondary,
	fontSize: 12,
	display: 'flex',
	zIndex: 1300,
	position: 'sticky',
	alignItems: 'center',
	'.logo-container': {
		display: 'flex',
		alignItems: 'center',
		marginRight: 160
	},
	'.logo-footer': {
		height: 28,
		marginRight: 20
	},
	'.footer-text': {
		fontSize: 9,
		color: darkColors.textColor.secondary,
		alignItems: 'center',
		'&:not(:first-of-type)': {
			marginLeft: 20
		}
	},
	a: {
		color: 'inherit',
		textDecoration: 'none',
		'&:hover': {
			textDecoration: 'underline'
		}
	},
	'.MuiSvgIcon-root': {
		color: darkColors.iconColor.mint
	}
}))

export default function DashboardFooter() {
	const user = useSelector(selectUser)
	const { currentPracticeObject } = useCurrentPractice()
	const practiceId = useMemo(
		() => currentPracticeObject?.stripeAccountId || currentPracticeObject?.vetClinicId || null,
		[currentPracticeObject?.stripeAccountId, currentPracticeObject?.vetClinicId]
	)

	return (
		<DashboardFooterContainer>
			<Box className="logo-container">
				<img
					className="logo-footer"
					src="/static/icons/logo-scratch-white.png"
					alt="Scratch Logo"
				/>
				{practiceId && <span className="footer-text">Practice ID: {practiceId}</span>}
				<span className="footer-text">Version {CURRENT_VERSION}</span>
			</Box>
			{(user?.consolidatorId === undefined || null) && (
				<>
					<GiftIcon fontSize="small" />
					<Link
						sx={{ ml: 2 }}
						href="https://scratchpay.referralrock.com/v2/1/register"
						target="_blank"
						rel="noreferrer"
					>
						Refer us &amp; earn $100
					</Link>
				</>
			)}
		</DashboardFooterContainer>
	)
}
