import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { formatUnixDate } from 'util/date'
import { Typography } from '@mui/material'
import { Banner, BannerVariant } from 'components/common/banner'
import { useCurrentPractice } from '../../../contexts/PracticeContext'
import { selectContext, selectUser } from '../../../selectors/auth'
import {
	hasPermissionForUpdatingStripeRequirements,
	shouldShowPracticeRequirements
} from './helpers'
import { getPracticeCreatedAtTimestamp } from '../../../util/practice'

const PracticeRequiredInfoAlertBox = () => {
	const { currentPracticeObject } = useCurrentPractice()
	const user = useSelector(selectUser)
	const context = useSelector(selectContext)

	const areChargesOrPayoutsDisabled = useMemo(
		() =>
			!currentPracticeObject?.stripeAccountStatus?.chargesEnabled ||
			!currentPracticeObject?.stripeAccountStatus?.payoutsEnabled,
		[
			currentPracticeObject?.stripeAccountStatus?.chargesEnabled,
			currentPracticeObject?.stripeAccountStatus?.payoutsEnabled
		]
	)

	const requiredInfo = useMemo(() => {
		if (!currentPracticeObject?.additionalStripeRequiredInfo) return ''
		const info = currentPracticeObject.additionalStripeRequiredInfo
		const tx = info.slice(0, 3).join(',')
		if (info.length > 3) return `${tx},...`
		return tx
	}, [currentPracticeObject?.additionalStripeRequiredInfo])

	const deadline = useMemo(() => {
		if (
			areChargesOrPayoutsDisabled ||
			!currentPracticeObject?.additionalStripeRequirements?.current_deadline
		) {
			return 'immediately'
		}
		return `by ${formatUnixDate(currentPracticeObject.additionalStripeRequirements.current_deadline, 'MM/dd/yy')}`
	}, [
		currentPracticeObject?.additionalStripeRequirements?.current_deadline,
		areChargesOrPayoutsDisabled
	])

	const showRequirements = useMemo(
		() => shouldShowPracticeRequirements(currentPracticeObject, user),
		[currentPracticeObject, user]
	)

	const userCanUpdateRequirements = useMemo(
		() => hasPermissionForUpdatingStripeRequirements(context, currentPracticeObject),
		[context, currentPracticeObject]
	)
	const practiceCreatedAt = useMemo(
		() => getPracticeCreatedAtTimestamp(currentPracticeObject),
		[currentPracticeObject]
	)

	const variant = useMemo(() => {
		const sixtyDaysAgo = new Date().getTime() / 1000 - 60 * 24 * 3600 // This is in seconds.
		const currentlyDue = currentPracticeObject?.additionalStripeRequirements?.currently_due || []
		const isRecentlyCreatedPractice = practiceCreatedAt > sixtyDaysAgo

		if (areChargesOrPayoutsDisabled && !isRecentlyCreatedPractice) {
			return BannerVariant.ERROR
		}

		if (
			isRecentlyCreatedPractice &&
			currentlyDue?.length > 0 &&
			!currentPracticeObject.consolidatorId
		) {
			return BannerVariant.INFO
		}
		return BannerVariant.ERROR
	}, [
		currentPracticeObject?.additionalStripeRequirements?.currently_due,
		currentPracticeObject.consolidatorId,
		practiceCreatedAt,
		areChargesOrPayoutsDisabled
	])

	const bannerSubtitle = useMemo(() => {
		if (variant === BannerVariant.ERROR) {
			return userCanUpdateRequirements
				? `Complete this ${deadline}, or you will be unable to process payments.`
				: `Prompt the practice manager to log in and complete this ${deadline}, or you will be unable to process payments.`
		}
		return (
			<Typography sx={{ fontSize: '0.85rem' }}>
				<b>Get Started:</b> Account information is needed before you can begin using Scratch.
			</Typography>
		)
	}, [userCanUpdateRequirements, deadline, variant])

	if (!showRequirements) {
		return null
	}

	return (
		<Banner
			tooltip={`Missing Info: ${requiredInfo}`}
			title={
				variant === BannerVariant.ERROR
					? 'Immediate Action Needed: Update your account information'
					: ''
			}
			subtitle={bannerSubtitle}
			buttonTitle={variant === BannerVariant.ERROR ? 'Update Now' : 'Update'}
			variant={variant}
			href="/dashboard/account/practice-required-info"
			showButton={userCanUpdateRequirements}
			showCloseButton={false}
		/>
	)
}

export default PracticeRequiredInfoAlertBox
