import { useState } from 'react'
import { Drawer } from '@mui/material'

const SettingsDrawer = () => {
	const [open, setOpen] = useState(false)

	const handleClose = () => {
		setOpen(false)
	}

	return (
		<Drawer
			anchor="right"
			onClose={handleClose}
			open={open}
			PaperProps={{
				sx: {
					p: 2,
					width: 320
				}
			}}
		/>
	)
}

export default SettingsDrawer
