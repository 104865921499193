import { createContext, useCallback, useContext, useEffect, useState, useMemo, useRef } from 'react'
import { useBeforeUnload } from 'react-router-dom'
import PropTypes from 'prop-types'
import toast from 'react-hot-toast'
import numeral from 'numeral'
import { collection, doc, onSnapshot } from 'firebase/firestore'
import { useSelector } from 'react-redux'
import { useRecentClientPaymentConfirmation } from 'components/dashboard/newTransaction/hooks/useRecentClientPaymentConfirmation'
import {
	checkIsCardDeclinedError,
	checkIsPaymentCancelledError,
	checkNoActivePaymentMethodError
} from '../util/stripe'
import { firestoreClient } from '../lib/firebase'
import { useCurrentPractice } from './PracticeContext'
import {
	cancelReaderPayment,
	createCheckoutPaymentServicePaymentIntent,
	getTerminalConnectionToken,
	processReaderPayment,
	simulateReaderSwipe,
	deleteSimulatedReader
} from '../apis/checkoutPaymentService'
import { capturePaymentIntent } from '../apis/paymentIntent'
import { logError } from '../util/logger'
import { convertDollarsToCents, displayLoadingToast, isProductionEnv } from '../util/util'
import errorToast from '../util/errorToast'
import { isMobileDevice } from '../util/mobile'
import {
	DENIED_AMEX_CARD_MESSAGE,
	STATE_AMEX_BLOCKED,
	STATE_CANCELED,
	STATE_FAILED,
	STATE_SUCCEEDED
} from '../constants'
import { isMVPPractice, isSurchargeEnabled } from '../util/practice'
import { shouldRequireInvoiceNumber } from '../util/consolidator'
import { useClientIdValidator } from '../components/dashboard/client/hooks'
import { createSimulatedReader } from '../apis/reader'
import { selectUser } from '../selectors/auth'
import useInvoiceNumberValidator from '../components/dashboard/newTransaction/hooks/use-invoice-number-validator'

export interface IReaderContext {
	terminal? // IStripeTerminal;
	cancelPayment?
	chargeAmount?
	clearReaderDisplay?
	collectCardPayment?
	connectionStatus?
	connectToReader?
	connectReaderHandler?

	setIsInteracTransaction?
	isInteracTransaction?

	disconnectReader?
	discoveredReaders?
	setDiscoveredReaders?
	discoverReaderHandler?
	discoveryInProgress?

	handleChargeAmountChange?
	handleClientID?
	handleDescriptionChange?
	handleInvoiceIDChange?

	setUseSimulatedReader?
	useSimulatedReader?
	simulateSwipe?

	processingTerminalPayment?

	reader?
	readerSelectionInProgress?
	unsavedChanges?
	setUnsavedChanges?
	connectToServerDrivenReader?
	cancelServerDrivenReaderPayment?
	chargeAmountError?
	clientIdError?
	invoiceIdError?

	clientID?
	showRecentClientPaymentDialog?
	handleAcceptRecentClientPayment?
	handleCloseRecentClientPaymentDialog?
}

const firestore = firestoreClient()
const ReaderContext = createContext<IReaderContext>({})

export function useReader() {
	return useContext(ReaderContext)
}

declare let StripeTerminal: any // TODO TSC switch to @stripe/terminal-js/pure?

export function ReaderProvider({ children }) {
	const { currentPracticeObject, terminalLocation, currentUserConsolidator } = useCurrentPractice()
	const currentUser: User = useSelector(selectUser)
	const stripeAccount = currentPracticeObject?.stripeAccountId
	const { validateClientId } = useClientIdValidator()
	const { isInvoiceNumberValid } = useInvoiceNumberValidator()
	// To Do: replace reader as the parent state with connection status
	const [terminal, setTerminal] = useState(
		StripeTerminal.create({
			onFetchConnectionToken: fetchConnectionToken,
			onUnexpectedReaderDisconnect: unexpectedDisconnect,
			onConnectionStatusChange: connectionStatusChange
		})
	)
	const createdSimReader = useRef<any>()
	const serverDrivenTerminal = useMemo(
		() =>
			currentPracticeObject?.serverDrivenTerminal ||
			isSurchargeEnabled(currentPracticeObject) ||
			false,
		[currentPracticeObject]
	)
	const [reader, setReader] = useState<any>()
	const [readerSelectionInProgress, setReaderSelectionInProgress] = useState(false)
	const [discoveryInProgress, setDiscoveryInProgress] = useState(false)
	const [discoveredReaders, setDiscoveredReaders] = useState([])
	const [processingTerminalPayment, setProcessingTerminalPayment] = useState(false)
	const [chargeAmount, setChargeAmount] = useState('')
	const [chargeAmountError, setChargeAmountError] = useState('')
	const [chargeDescription, setChargeDescription] = useState('')
	const [clientID, setClientID] = useState('')
	const [clientIdError, setClientIdError] = useState('')
	const [invoiceID, setInvoiceID] = useState('')
	const [invoiceIdError, setInvoiceIdError] = useState('')
	const [connectionStatus, setConnectionStatus] = useState(terminal.getConnectionStatus())

	const [useSimulatedReader, setUseSimulatedReader] = useState(false)
	const [unsavedChanges, setUnsavedChanges] = useState(false)
	const [isInteracTransaction, setIsInteracTransaction] = useState(false)
	const [processingPaymentIntent, setProcessingPaymentIntent] = useState<any>(null)
	const PAYMENT_INTENTS_COLLECTION = 'paymentIntents'
	const PAYMENT_CANCELED = 'Payment Canceled'

	const isMvpClinic = useMemo(() => isMVPPractice(currentPracticeObject), [currentPracticeObject])
	const requireInvoiceNumber = useMemo(
		() => shouldRequireInvoiceNumber(currentUserConsolidator),
		[currentUserConsolidator]
	)

	const {
		checkIfShouldCreatePayment,
		showRecentClientPaymentDialog,
		handleAcceptRecentClientPayment,
		handleCloseRecentClientPaymentDialog
	} = useRecentClientPaymentConfirmation()

	function waitForPaymentIntentStatus(paymentIntentId, paymentIntentStatus) {
		let unsubscribeSnapshot
		let paymentIntentPromiseResolve

		const paymentIntentPromise = new Promise((resolve, reject) => {
			paymentIntentPromiseResolve = resolve
			unsubscribeSnapshot = onSnapshot(
				doc(collection(firestore, PAYMENT_INTENTS_COLLECTION), paymentIntentId),
				(document) => {
					const d = document.data()
					const status = d?.status || ''

					if (status === paymentIntentStatus) {
						resolve(paymentIntentStatus)
					}

					if (status === STATE_CANCELED && d?.metadata?.amexCardBlocked === 'true') {
						resolve(STATE_AMEX_BLOCKED)
					}

					if ([STATE_FAILED, STATE_CANCELED].includes(status)) {
						resolve(STATE_FAILED)
					}
				},
				(error) => {
					reject(error)
				}
			)
		})

		// unsubscribes snapshot listener after resolve or reject
		paymentIntentPromise.finally(() => {
			unsubscribeSnapshot()
		})

		return {
			wait: () => paymentIntentPromise,
			cancel: () => paymentIntentPromiseResolve(PAYMENT_CANCELED)
		}
	}

	async function connectToServerDrivenReader(selectedReader) {
		setReaderSelectionInProgress(true)
		setReader(selectedReader)
		setConnectionStatus('connected')
		setReaderSelectionInProgress(false)
		return selectedReader
	}

	async function connectToReader(selectedReader) {
		setReaderSelectionInProgress(true)
		try {
			const connectResult = await terminal.connectReader(selectedReader)
			const { reader: Reader } = await connectResult
			console.log('Reader, should == readerObject', Reader)
			const readerObject = await connectResult.reader
			console.log('Reader Connection Result', connectResult)
			console.log('readerObject', readerObject)
			if (connectResult.error) {
				console.log('Failed to connect:', connectResult.error)
				setReaderSelectionInProgress(false)
				return
			}
			console.log('Reader Connection Result', connectResult)
			console.log('reader', connectResult.reader)
			setReader(connectResult.reader)
			setReaderSelectionInProgress(false)
			return Reader
		} catch (e) {
			console.log(e)
		}
	}

	async function disconnectReader() {
		try {
			await terminal.disconnectReader()
			setReader(undefined)
		} catch (e) {
			console.log('DisconnectReader Error', e)
		}
	}

	async function discoverReaderHandler() {
		try {
			setDiscoveryInProgress(true)
			if (!terminalLocation) {
				setDiscoveredReaders([])
				setDiscoveryInProgress(false)
				console.log('No Terminal Location Set')
				return
			}

			const config = { location: terminalLocation, simulated: false }
			const simulatedReaders: Record<string, unknown>[] = []
			if (!isProductionEnv() && useSimulatedReader) {
				config.simulated = true

				if (serverDrivenTerminal) {
					if (createdSimReader.current) {
						simulatedReaders.push(createdSimReader.current)
					} else {
						// If server driven, you have to create the terminal
						try {
							const simReader: Record<string, unknown> = await createSimulatedReader(
								currentPracticeObject?.id,
								config.location
							)
							createdSimReader.current = simReader
							simulatedReaders.push(simReader)
						} catch (err: any) {
							console.error('createSimulatedReader Error', err)
						}
					}
				}
			}

			if (simulatedReaders.length > 0) {
				setDiscoveredReaders(simulatedReaders as any)
				console.log('terminal.discoverReaders', simulatedReaders)
			} else {
				const discoverResult = await terminal.discoverReaders(config)
				if (discoverResult.error) {
					setDiscoveryInProgress(false)
					toast.error('Something went wrong when searching, please try again.')
					console.error(
						'Something went wrong when searching, please try again',
						discoverResult.error
					)
					return
				}
				if (discoverResult.discoveredReaders.length === 0) {
					setDiscoveredReaders([])
					setDiscoveryInProgress(false)
					console.log('No card readers were discovered at your location')
					return
				}
				setDiscoveredReaders(discoverResult.discoveredReaders)
				console.log('terminal.discoverReaders', discoverResult.discoveredReaders)
			}
			setDiscoveryInProgress(false)
		} catch (e) {
			logError(e, true, 'discoverReaderHandler Error')
			setDiscoveryInProgress(false)
		}
		setDiscoveryInProgress(false)
	}

	const resetFields = useCallback(() => {
		setProcessingTerminalPayment(false)
		setIsInteracTransaction(false)
		setChargeAmount('')
		setChargeDescription('')
		setClientID('')
	}, [])

	async function fetchConnectionToken() {
		try {
			const { data } = await getTerminalConnectionToken()
			console.log('fetchConnectionToken Done')
			return data.terminal_secret_token
		} catch (err) {
			console.log('fetchConnectionToken Error', err)
			return err
		}
	}

	async function unexpectedDisconnect() {
		try {
			console.log('Unexpected Disconnected from reader')
			setConnectionStatus('not_connected')
			// API call to get reader object based on this ReaderID
			// const stripe = require('stripe')('sk_test_h5drEhs1A0hBZAawI7jyhc1C00CSRBushi');
			// const reader = await stripe.terminal.readers.retrieve('tmr_P400-123-456-789');
			// https://stripe.com/docs/api/terminal/readers/retrieve
			await connectToReader(reader.id)
			// connectToReader(reader);
			// setReader();
		} catch (err) {
			console.log('TryError', err)
		}
	}

	async function connectionStatusChange() {
		setConnectionStatus(terminal.getConnectionStatus())
		console.log('Reader ConnectionStatus-Change', terminal.getConnectionStatus())
		console.log({ connectionStatus })
	}

	async function connectReaderHandler(terminalId) {
		try {
			setDiscoveryInProgress(true)
			const readers = await terminal.discoverReaders({
				location: terminalLocation
			})
			if (readers.error) {
				console.log('Unable to find readers', readers.error)
				return
			}
			const preferredReader = readers.discoveredReaders.find((r) => r.id === terminalId)
			await connectToReader(preferredReader)
		} catch (e) {
			console.log('connectReaderHandler Error', e)
			setDiscoveryInProgress(false)
		}
		setDiscoveryInProgress(false)
	}

	async function collectCardPayment() {
		if (!chargeAmount) return setChargeAmountError('Amount is required')
		if (isMvpClinic && !clientID) return setClientIdError('Client Id is required')
		try {
			await validateClientId(clientID)
		} catch (err: any) {
			setClientIdError(err.message)
			return
		}
		if (requireInvoiceNumber && !invoiceID) return setInvoiceIdError('Invoice Number is required')
		if (invoiceID && !isInvoiceNumberValid(invoiceID))
			return setInvoiceIdError('Only numbers can be entered')

		let toastId = displayLoadingToast('Connecting to Reader...')

		try {
			const amountInCents = convertDollarsToCents(+chargeAmount.replace(/,/g, ''))
			const [shouldCreatePayment, showToastAgain] = await checkIfShouldCreatePayment(
				clientID,
				stripeAccount!,
				amountInCents,
				toastId
			)

			if (!shouldCreatePayment) {
				return
			}

			if (showToastAgain) {
				toastId = displayLoadingToast('Connecting to Reader...')
			}

			setProcessingTerminalPayment(true)

			const paymentMethodTypes = ['card_present']
			if (isInteracTransaction) {
				paymentMethodTypes.push('interac_present')
			}

			const createCheckoutPaymentIntentResponse = await createCheckoutPaymentServicePaymentIntent({
				params: {
					paymentMethodType: 'card_present'
				},
				body: {
					amount_due: Number(chargeAmount),
					practice_account_id: stripeAccount!,
					last_processed_by: currentUser.email,
					multiple_payment: false,
					auto_capture: true,
					no_checks: false,
					source: 'dashboard',
					created_from_mobile: isMobileDevice(),
					...(invoiceID && { invoice_number: invoiceID }),
					...(clientID && { client_id: clientID }),
					...(chargeDescription && { description: chargeDescription })
				}
			})
			const createPaymentIntentResponse = {
				id: createCheckoutPaymentIntentResponse.data.payment_intent_id,
				client_secret: createCheckoutPaymentIntentResponse.data.payment_intent_client_secret
			}

			let collectPaymentMethodResponse
			if (serverDrivenTerminal) {
				try {
					toast.loading('Processing Payment...', {
						id: toastId,
						duration: 10000
					})
					collectPaymentMethodResponse = await processReaderPayment(
						reader.id,
						createPaymentIntentResponse.id
					)

					// wait for the paymentIntent status to be succeeded
					const paymentStatusAwaiter = waitForPaymentIntentStatus(
						createPaymentIntentResponse.id,
						STATE_SUCCEEDED
					)
					setProcessingPaymentIntent({
						cancel: paymentStatusAwaiter.cancel
					})
					const paymentIntentStatus = await paymentStatusAwaiter.wait()

					setProcessingPaymentIntent(null)
					resetFields()
					if (paymentIntentStatus === PAYMENT_CANCELED) {
						toast.dismiss(toastId)
						return
					}

					if (paymentIntentStatus === STATE_FAILED) {
						toast.error('Payment Failed!', {
							id: toastId,
							duration: 10000
						})
					} else if (paymentIntentStatus === STATE_AMEX_BLOCKED) {
						toast.error(DENIED_AMEX_CARD_MESSAGE, {
							id: toastId,
							duration: 10000
						})
					} else {
						toast.success('Payment successful!', {
							id: toastId,
							duration: 10000
						})
					}
				} catch (err: any) {
					if (err?.message || err?.response?.data?.error_message) {
						toast.error(`Payment Failed: ${err?.response?.data?.error_message || err?.message}`, {
							id: toastId
						})
						logErrorBasedOnStatus(err)
					}
					resetFields()
				}
				return
			}

			collectPaymentMethodResponse = await terminal.collectPaymentMethod(
				createPaymentIntentResponse.client_secret
			)

			toast.loading('Getting Card Info...', {
				id: toastId,
				duration: 10000
			})
			toast.loading('Sending Card Info...', {
				id: toastId,
				duration: 10000
			})
			if (collectPaymentMethodResponse.error) {
				if (collectPaymentMethodResponse.error.code === 'canceled') {
					toast.dismiss(toastId)
					resetFields()
					return
				}
				toast.error(
					`Collect payment method failed: ${collectPaymentMethodResponse.error.message}`,
					{
						id: toastId
					}
				)
				resetFields()
				return
			}

			let processPaymentResponse
			if (collectPaymentMethodResponse.paymentIntent) {
				processPaymentResponse = await terminal.processPayment(
					collectPaymentMethodResponse.paymentIntent
				)
			}

			if (!processPaymentResponse || processPaymentResponse.error) {
				if (processPaymentResponse.error) {
					logErrorBasedOnStatus(processPaymentResponse.error)
					toast.error(`Confirm result failed: ${processPaymentResponse.error.message}`, {
						id: toastId
					})
				} else {
					toast.error('Something went wrong while processing payment.', {
						id: toastId
					})
				}
			} else if (
				processPaymentResponse.paymentIntent.status !== STATE_SUCCEEDED &&
				!isInteracTransaction
			) {
				try {
					const capturePaymentIntentResponse = await capturePaymentIntent(
						processPaymentResponse.paymentIntent.id
					)
					const paymentMethodDetails =
						capturePaymentIntentResponse.charges?.data[0]?.payment_method_details?.card_present
					const amountCaptured = numeral(
						Number(capturePaymentIntentResponse?.charges?.data[0]?.amount_captured) / 100.0
					).format('0,0.00')
					toast.success(
						<div>
							<br />
							<b>PAYMENT SUCCESSFUL!</b>
							<br />
							<br />
							Card: <b>{paymentMethodDetails?.brand.toUpperCase()} </b>
							<br />
							Last 4: <b>{paymentMethodDetails?.last4}</b>
							<br />
							Amount: <b>${amountCaptured}</b>
						</div>,
						{
							duration: 15000,
							id: toastId
						}
					)
				} catch (e: any) {
					logError(e)
					toast.error(`${e.message}`, {
						id: toastId
					})
				}
			} else {
				toast.success('Payment Successful!', {
					id: toastId
				})
			}
		} catch (e) {
			logError(e)
			errorToast(e, { id: toastId })
			// errorToast<CheckoutPaymentServiceErrorData>(e, { id: toastId });
		}
		resetFields()
	}

	function handleDescriptionChange(description) {
		setChargeDescription(description)
	}

	function handleChargeAmountChange(amount) {
		setChargeAmount(amount.replace(/,/g, ''))
		setChargeAmountError('')
	}

	function handleClientID(clientId) {
		setClientID(clientId)
		setClientIdError('')
	}

	function handleInvoiceIDChange(invoiceId) {
		setInvoiceID(invoiceId)
		if (!isInvoiceNumberValid(invoiceId)) {
			return setInvoiceIdError('Only numbers can be entered')
		}
		setInvoiceIdError('')
	}

	async function cancelServerDrivenReaderPayment() {
		const toastId = toast.loading('Clearing reader display...')
		try {
			await cancelReaderPayment(reader.id)
			if (processingPaymentIntent) {
				processingPaymentIntent.cancel()
				setProcessingPaymentIntent(null)
			}
			setChargeAmount('')
			setChargeDescription('')
			setClientID('')
			setInvoiceID('')
			setProcessingTerminalPayment(false)
			toast.success('Payment Canceled', {
				id: toastId
			})
		} catch (err: any) {
			console.error(err)
			toast.error(
				`Failed to clear reader display: ${err?.response?.data?.error_message || err?.message}`,
				{
					id: toastId
				}
			)
		}
	}

	async function simulateSwipe(brand: string, funding: string, error?: string) {
		const toastId = toast.loading('Simulating a swipe on the reader...')
		try {
			await simulateReaderSwipe(reader.id, brand, funding, error)
			toast.success('Swipe accepted', {
				id: toastId
			})
		} catch (err: any) {
			console.error(err)
			toast.error(
				`Failed to simulate a reader swipe: ${err?.response?.data?.error_message || err?.message}`,
				{
					id: toastId
				}
			)
		}
	}

	// 3b. CANCEL CARD PAYMENT
	async function cancelPayment() {
		if (serverDrivenTerminal) return cancelServerDrivenReaderPayment()
		try {
			await terminal.cancelCollectPaymentMethod()
			setChargeAmount('')
			setChargeDescription('')
			setClientID('')
			setInvoiceID('')
		} catch (err: any) {
			logErrorBasedOnStatus(err?.error || err, 'CancelPayment error')
		}
		await terminal.clearReaderDisplay()
	}

	async function clearReaderDisplay() {
		try {
			await terminal.clearReaderDisplay()
			setChargeAmount('')
			setChargeDescription('')
			setClientID('')
			setInvoiceID('')
		} catch (e) {
			logError(e, true, 'clearReaderDisplay Error')
		}
	}

	const handleTerminal = async () => {
		setTerminal(
			StripeTerminal.create({
				onFetchConnectionToken: fetchConnectionToken,
				onUnexpectedReaderDisconnect: unexpectedDisconnect,
				onConnectionStatusChange: connectionStatusChange
			})
		)
	}

	const logErrorBasedOnStatus = (err: any, customMessage?: string) => {
		const isPaymentCancelled = checkIsPaymentCancelledError(err)
		const isCardDeclined = checkIsCardDeclinedError(err)
		const isNoActivePaymentMethod = checkNoActivePaymentMethodError(err)
		const shouldLogOnSentry = !isPaymentCancelled && !isCardDeclined && !isNoActivePaymentMethod
		logError(err, shouldLogOnSentry, customMessage)
	}

	// TO DO: Is this useEffect needed, or can it be removed?
	useEffect(() => {
		window.addEventListener('terminal listener', handleTerminal)
		console.log('Terminal SDK EventListener Added', terminal)
		return () => {
			window.removeEventListener('remove terminal listener', handleTerminal)
			console.log('Terminal SDK EventListener Removed', terminal)
		}
	}, [])

	useEffect(() => {
		if (!useSimulatedReader && reader) {
			disconnectReader()
		}
	}, [useSimulatedReader])

	// Delete the simulated reader before unloading
	useBeforeUnload(
		useCallback(() => {
			if (createdSimReader.current) {
				deleteSimulatedReader(createdSimReader.current.id).catch((err) => console.error(err))
			}
		}, [createdSimReader])
	)

	// TO DO: if the reader disconnects it is shown in Connection Status Change: not_connected
	useEffect(() => {
		window.addEventListener('terminal listener', handleTerminal)
		discoverReaderHandler()

		return () => {
			window.removeEventListener('remove terminal listener', handleTerminal)
			console.log('Terminal SDK EventListener Removed', terminal)
		}
	}, [terminalLocation, useSimulatedReader])

	const context = {
		terminal,
		cancelPayment,
		chargeAmount,
		clearReaderDisplay,
		collectCardPayment,
		connectionStatus,
		connectToReader,
		connectReaderHandler,

		setIsInteracTransaction,
		isInteracTransaction,

		disconnectReader,
		discoveredReaders,
		setDiscoveredReaders,
		discoverReaderHandler,
		discoveryInProgress,

		handleChargeAmountChange,
		handleClientID,
		handleDescriptionChange,
		handleInvoiceIDChange,

		setUseSimulatedReader,
		useSimulatedReader,
		simulateSwipe,

		processingTerminalPayment,

		reader,
		readerSelectionInProgress,
		unsavedChanges,
		setUnsavedChanges,
		connectToServerDrivenReader,
		cancelServerDrivenReaderPayment,
		chargeAmountError,
		clientIdError,
		invoiceIdError,

		clientID,
		showRecentClientPaymentDialog,
		handleAcceptRecentClientPayment,
		handleCloseRecentClientPaymentDialog
	}

	return <ReaderContext.Provider value={context}>{children}</ReaderContext.Provider>
}

ReaderProvider.propTypes = {
	children: PropTypes.node.isRequired
}

export default ReaderProvider
