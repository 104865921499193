import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

import { authClient, logout } from '../lib/firebase'
import { SCRATCHPAY_CASH_BACKEND_CONFIG, CHECKOUT_PAYMENT_SERVICE_CONFIG } from '../config'
import { constructAuthHeader } from '../util/api-helpers'
import { captureException } from '../util/sentry'

const interceptRequest = async (config) => {
	const authHeader = await constructAuthHeader()
	return {
		...config,
		headers: {
			...config.headers,
			Authorization: authHeader
		}
	}
}

const constructCheckoutPaymentServiceAuthHeader = async () => {
	const accessToken = authClient().currentUser
		? await authClient().currentUser?.getIdToken()
		: undefined
	return `Token ${accessToken}`
}

const interceptCheckoutPaymentServiceRequest = async (config) => {
	const authHeader = await constructCheckoutPaymentServiceAuthHeader()
	return {
		...config,
		headers: {
			...config.headers,
			Authorization: authHeader
		}
	}
}

const intercepResponseError = async (error) => {
	if (error.response) {
		switch (error.response.status) {
			case 401:
			case 403:
				logout()
				break
			case 500:
				captureException(error)
				break
			default:
				break
		}
	}
	return Promise.reject(error)
}

// We use (response)=>response.data.  It's not the best style, but this is how you can make it DTRT and not require .data
export type AxiosClientDirect = Omit<
	AxiosInstance,
	| 'request'
	| 'get'
	| 'delete'
	| 'head'
	| 'options'
	| 'post'
	| 'put'
	| 'patch'
	| 'postForm'
	| 'putForm'
	| 'patchForm'
> & {
	request<T = any, D = any>(config: AxiosRequestConfig<D>): T
	get<T = any, D = any>(url: string, config?: AxiosRequestConfig<D>): T
	delete<T = any, D = any>(url: string, config?: AxiosRequestConfig<D>): T
	head<T = any, D = any>(url: string, config?: AxiosRequestConfig<D>): T
	options<T = any, D = any>(url: string, config?: AxiosRequestConfig<D>): T
	post<T = any, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): T
	put<T = any, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): T
	patch<T = any, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): T
	postForm<T = any, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): T
	putForm<T = any, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): T
	patchForm<T = any, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): T
}

const axiosClientRaw = axios.create({
	baseURL: SCRATCHPAY_CASH_BACKEND_CONFIG.baseURL,
	headers: {
		'Content-Type': 'application/json',
		Accept: 'application/json'
	}
})
axiosClientRaw.interceptors.request.use(interceptRequest)
axiosClientRaw.interceptors.response.use((response) => response.data, intercepResponseError)
const axiosClient: AxiosClientDirect = axiosClientRaw as AxiosClientDirect

const checkoutPaymentServiceAxiosClientRaw = axios.create({
	baseURL: CHECKOUT_PAYMENT_SERVICE_CONFIG.baseURL,
	headers: {
		'Content-Type': 'application/json',
		Accept: 'application/json'
	}
})
checkoutPaymentServiceAxiosClientRaw.interceptors.request.use(
	interceptCheckoutPaymentServiceRequest
)
checkoutPaymentServiceAxiosClientRaw.interceptors.response.use(
	(response) => response.data,
	intercepResponseError
)
const checkoutPaymentServiceAxiosClient: AxiosClientDirect =
	checkoutPaymentServiceAxiosClientRaw as AxiosClientDirect

/**
 * Get a downloadable link from the backend.  Axios assumes the result is json, whereas we want the raw data here.
 * @param url the url to hit for the backend
 * @param body the JSON object to send along with the request
 * @param defaultFileName the defaultFilename to use if the filename isn't provided with the result.
 * @param corsRedirect if we expect the file to come from a google bucket, turn this on so it assumes the link returns a
 */
async function getDownloadLinkFromBackend(
	url: string,
	body: any,
	defaultFileName: string,
	corsRedirect: boolean = false
) {
	const { baseURL } = SCRATCHPAY_CASH_BACKEND_CONFIG
	const firstLink = constructAuthHeader().then((authHeader) =>
		fetch(`${baseURL}${url}`, {
			method: corsRedirect ? 'POST' : 'GET', // Assume if doing cors, we want the POST first
			referrerPolicy: 'origin',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authHeader
			},
			body: corsRedirect ? JSON.stringify(body || {}) : undefined
		})
	)

	// If doing a corsRedirect, we need to get the response as text and then retrieve the url and then
	// redownload.  Cors doesn't send referrer on a redirect across domain, so we have to do it by hand.
	if (corsRedirect) {
		return firstLink.then(async (response: Response) => {
			// Get the url and then fetch that.
			if (!response.ok) {
				throw new Error(response.statusText)
			}
			const data = await response.json()

			const a = document.createElement('a')
			a.href = data.data.url
			a.download = defaultFileName
			a.click()
		})
	}

	// Assume it's less than 10MB and we can just stream it
	return firstLink
		.then((response) => response.blob())
		.then((blob) => {
			const a = document.createElement('a')
			a.href = window.URL.createObjectURL(blob)
			a.download = defaultFileName
			a.click()
		})
}

export { checkoutPaymentServiceAxiosClient, getDownloadLinkFromBackend }

export default axiosClient
