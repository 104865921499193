import { matchPath, NavLink as RouterLink } from 'react-router-dom'
import { Button, Link, List, ListSubheader } from '@mui/material'
import NavItem from './NavItem'
import { useCurrentPractice } from '../contexts/PracticeContext'

type RenderNavItemProps = {
	depth?: number
	items: any[]
	pathname: string
	currentPracticeObject?: IPractice
}

const renderNavItems = ({
	depth = 0,
	items,
	pathname,
	currentPracticeObject
}: RenderNavItemProps) => (
	<List disablePadding>
		{items.reduce(
			(acc, item) =>
				reduceChildRoutes({
					acc,
					item,
					pathname,
					depth,
					currentPracticeObject
				}),
			[]
		)}
	</List>
)

const reduceChildRoutes = ({ acc, pathname, item, depth, currentPracticeObject }) => {
	if (
		item.practiceAllowedProperty &&
		// Check to see if the practice has the property enabled in the practice object OR settings
		!(
			currentPracticeObject?.settings?.[item.practiceAllowedProperty] ||
			currentPracticeObject?.[item.practiceAllowedProperty]
		)
	) {
		return acc
	}
	const key = `${item.title}-${depth}`
	const exactMatch = item.path
		? !!matchPath(
				{
					path: item.path,
					end: true
				},
				pathname
			)
		: false

	if (item.children) {
		const partialMatch = item.path
			? !!matchPath(
					{
						path: item.path,
						end: false
					},
					pathname
				)
			: false

		acc.push(
			<NavItem
				active={partialMatch}
				depth={depth}
				icon={item.icon}
				info={item.info}
				key={key}
				open={partialMatch}
				path={item.path}
				title={item.title}
			>
				{renderNavItems({
					depth: depth + 1,
					items: item.children,
					pathname
				})}
			</NavItem>
		)
	} else {
		acc.push(
			<NavItem
				active={exactMatch}
				depth={depth}
				icon={item.icon}
				info={item.info}
				key={key}
				path={item.path}
				title={item.title}
			/>
		)
	}

	return acc
}

type NavSectionProps = {
	items?: any[]
	pathname: string
	title: string
	signupLink?: string
	sx: any
}

const NavSection = ({ items, pathname, title, signupLink, ...other }: NavSectionProps) => {
	const { currentPracticeObject } = useCurrentPractice()

	return (
		<List
			subheader={
				<ListSubheader
					disableGutters
					disableSticky
					sx={{
						color: 'text.secondary',
						fontSize: '0.875rem',
						lineHeight: 2.5,
						fontWeight: 700,
						textDecoration: 'none',
						textTransform: 'uppercase',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between'
					}}
				>
					{!signupLink && <span>{title}</span>}
					{!!signupLink && (
						<>
							<Link
								component={RouterLink}
								to={signupLink}
								sx={{
									color: 'inherit',
									cursor: 'pointer',
									textDecoration: 'none'
								}}
							>
								{title}
							</Link>
							<Button
								component={RouterLink}
								variant="contained"
								size="small"
								to={signupLink}
								sx={{
									borderRadius: '5px !important',
									color: '#E2E3FF !important',
									cursor: 'pointer',
									width: 75,
									height: 25,
									fontWeight: 600,
									backgroundColor: '#5B70FF !important'
								}}
							>
								Sign up
							</Button>
						</>
					)}
				</ListSubheader>
			}
			{...other}
		>
			{items &&
				renderNavItems({
					items,
					pathname,
					currentPracticeObject
				})}
		</List>
	)
}

export default NavSection
