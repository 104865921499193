import { useDispatch as useReduxDispatch, useSelector as useReduxSelector } from 'react-redux'
import { configureStore } from '@reduxjs/toolkit'
import rootReducer from './rootReducer'

const store = configureStore({
	reducer: rootReducer,
	devTools: process.env.REACT_APP_ENABLE_REDUX_DEV_TOOLS === 'true',
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false
		})
})

export const useSelector = useReduxSelector

export type AppDispatch = typeof store.dispatch

export const useDispatch = () => useReduxDispatch()

export default store
