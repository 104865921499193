import { Box, Container } from '@mui/material'

export default function LoadingSpinner() {
	return (
		<Box
			sx={{
				backgroundColor: 'background.default',
				display: 'flex',
				flexDirection: 'column',
				minHeight: '100vh'
			}}
		>
			<Container maxWidth="sm" sx={{ py: '80px' }}>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						mb: 2
					}}
				>
					<img src="/static/loading-heart.gif" height="120" width="120" alt="Loading ...." />
				</Box>
			</Container>
		</Box>
	)
}
