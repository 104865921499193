import { AxiosResponse } from 'axios'
import { createClient } from 'urql'
import { LIST_ACTION_NEEDED, gqlClientOptions } from 'gql'
import axiosClient from './base'
import { RequestLevel } from '../constants'

const RESOLVE_ACTION_ITEM_PATH = (id: string) => `/api/action-needed/${id}/resolve`

// ResolveActionItem v2
const RESOLVE_ACTION_ITEM_PATH_V2 = (id: string) => `/api/action-needed/${id}/resolve-action`

const UPDATE_ACTION_ITEM_PATH = (id: string) => `/api/action-needed/${id}`

const UPDATE_ACTION_ITEM_PATH_V2 = (id: string) => `/api/action-needed/${id}/update-action`

// TODO: Remove this once v2 is accepted
export const resolveActionItem = (
	id: string,
	data: IResolveActionItemParams
): Promise<AxiosResponse<IActionItemUpdateResponse>> =>
	axiosClient.post<Promise<AxiosResponse<IActionItemUpdateResponse>>, IResolveActionItemParams>(
		RESOLVE_ACTION_ITEM_PATH(id),
		data
	)

// ResolveActionItem v2
export const resolveAction = (
	id: string,
	data: IResolveActionItemParams
): Promise<AxiosResponse<IActionItemUpdateResponse>> =>
	axiosClient.post<Promise<AxiosResponse<IActionItemUpdateResponse>>, IResolveActionItemParams>(
		RESOLVE_ACTION_ITEM_PATH_V2(id),
		data
	)

// TODO: Remove this once v2 is accepted
export const updateActionItem = (
	id: string,
	data: IUpdateActionItemParams
): Promise<AxiosResponse<IActionItemUpdateResponse>> =>
	axiosClient.put<Promise<AxiosResponse<IActionItemUpdateResponse>>, IUpdateActionItemParams>(
		UPDATE_ACTION_ITEM_PATH(id),
		data
	)

// UpdateActionItem v2
export const updateActionItemV2 = (
	id: string,
	data: IUpdateActionItemParams
): Promise<AxiosResponse<IActionItemUpdateResponse>> =>
	axiosClient.put<Promise<AxiosResponse<IActionItemUpdateResponse>>, IUpdateActionItemParams>(
		UPDATE_ACTION_ITEM_PATH_V2(id),
		data
	)

export const getPendingActionItemsV2 = (
	level: RequestLevel,
	take?: number,
	practiceId?: string
) => {
	const graphQLClientOptions = {
		practiceId,
		requestLevel: level
	}
	const graphQLClient = createClient(gqlClientOptions(graphQLClientOptions))
	return graphQLClient
		.query(LIST_ACTION_NEEDED, {
			where: {
				isComplete: false
			},
			take: take ?? 1
		})
		.toPromise()
}
