export const normalize = (str) => str?.trim().toLowerCase()

export const capitalizeFirstLetter = (str) => ''.concat(str?.charAt(0).toUpperCase(), str?.slice(1))

export const normalizeClinicName = (str) =>
	str
		?.trim()
		.replace(/[^a-zA-Z ]/g, '')
		.substring(0, 22)

export const snakeCaseToWords = (snakeCase: string): string =>
	snakeCase
		.split('_')
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
		.join(' ')

/**
 * `isNumericString` function
 * Determines if the provided string consists entirely of numeric characters.
 *
 * @function
 * @param {string} str - The string to be checked.
 * @returns {boolean} - Returns `true` if the string contains only numeric characters (0-9),
 *                      and `false` otherwise.
 */
export const isNumericString = (str) => /^\d+$/.test(str)

/**
 * `makeReqLabel` function
 * Formats and returns the provided label string to indicate whether an associated input is required.
 *
 * @function
 * @param {string} label - The original label string that you want to format.
 * @param {boolean} [isRequired=true] - Optional parameter. Determines whether to append a '*' to the label.
 *                                      If `true` (default), the function appends '*' to the label string.
 *                                      If `false`, it returns the original label string without any modification.
 * @returns {string} - Formatted label string based on the `isRequired` flag.
 */
export const makeReqLabel = (label: string, isRequired: boolean = true) =>
	isRequired ? `${label} *` : label

export const replaceUnderscores = (str: string = '') => str?.replace(/_/g, ' ')
