import { useMemo } from 'react'
import * as Yup from 'yup'
import { useCurrentPractice } from '../../../../contexts/PracticeContext'
import { getValidateClientIdSettings } from '../../../../util/consolidator'

const useClientIdValidator = () => {
	const { currentUserConsolidator } = useCurrentPractice()
	const validateClientIdSettings: ValidateClientIdSettings | undefined = useMemo(
		() => getValidateClientIdSettings(currentUserConsolidator),
		[currentUserConsolidator]
	)
	const shouldValidateClientId = validateClientIdSettings?.enabled

	const validateClientId = async (clientId: string | undefined) => {
		if (validateClientIdSettings?.enabled) {
			const validateSchema = Yup.string().matches(
				new RegExp(validateClientIdSettings.regex),
				validateClientIdSettings.errorMessage
			)
			await validateSchema.validate(clientId)
		} else {
			return true
		}
	}

	const getYupClientIdSchema = (
		fallbackSchema: Yup.StringSchema = Yup.string()
	): Yup.StringSchema => {
		if (validateClientIdSettings?.enabled) {
			/**
			 * Adding required() to the end of the schema because if no value is provided, the regex will still pass.
			 * because Yup.shape ignores empty value and Yup.string().matches() will pass if the value is empty.
			 * This is not the case in validateClientId because Yup.string().matches() will fail if the value is empty.
			 */
			return Yup.string()
				.matches(new RegExp(validateClientIdSettings.regex), validateClientIdSettings.errorMessage)
				.required(validateClientIdSettings.errorMessage)
		}
		return fallbackSchema
	}

	return { validateClientId, getYupClientIdSchema, shouldValidateClientId }
}

export default useClientIdValidator
