import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	isAuthCheckLoading: true,
	isAuthCheckUsingTokenLoading: true,
	user: null,
	context: null
}

const slice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setIsAuthCheckLoading(state, action) {
			state.isAuthCheckLoading = action.payload
		},
		setIsAuthCheckUsingTokenLoading(state, action) {
			state.isAuthCheckUsingTokenLoading = action.payload
		},
		setUser(state, action) {
			state.isAuthCheckLoading = false
			state.user = action.payload
		},
		setContext(state, action) {
			state.isAuthCheckLoading = false
			if (action.payload) {
				state.isAuthCheckUsingTokenLoading = false
			}
			state.context = action.payload
			state.user = action.payload?.user
		}
	}
})

export const { reducer } = slice
export const { setIsAuthCheckLoading, setUser, setContext, setIsAuthCheckUsingTokenLoading } =
	slice.actions

export default slice
