import { captureException, captureMessage } from './sentry'

// logError logs the error to console and publishes it to sentry
export const logError = (error, shouldCaptureException = true, customErrorMessage = '') => {
	console.error(customErrorMessage, error)
	if (shouldCaptureException) captureException(error)
}

// logMessage logs a message as error and publishes it to sentry as custom message
export const logMessage = (customMessage: string) => {
	console.error(customMessage)
	captureMessage(customMessage)
}
