import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	list: []
}

const slice = createSlice({
	name: 'users',
	initialState,
	reducers: {
		setUserslist(state, action) {
			state.list = action.payload
		}
	}
})

export const { reducer } = slice
export const { setUserslist } = slice.actions

export default slice
